<template>
  <ItemBase
    class="ItemSelectFavorites"
    :label="$t('label')"
    :title="$t('title')"
    :icon="icon"
    :progress="$attrs.progress"
    :pulse="$attrs.pulse"
    @click="open()" />
</template>

<translations>
  title: 'Choose<br> your interests'
  title_no: 'Velg<br> dine interesser'
  label: 'Personal'
  label_no: 'Personlig'
</translations>

<script>
import ItemBase from './ItemBase';
import icon from './icons/clapped-hand.svg';

export default {
    components: { ItemBase },
    created() {
        this.icon = icon;
    },
    methods: {
        open() {
            this.$router.push({
                path: '/follow'
            });
        }
    }
};
</script>
