<template>
  <div class="RocketContainer">
    <div class="rocketTop">
      <div class="skillsTitle">{{ title }}</div>
      <img class="rocketTopImage" src="./assets/rocket-top.svg" alt="" />
    </div>

    <div
      ref="scrollContainer"
      class="braindatesContainer"
      :class="{ justScrolled }"
      @mousedown="startScroll"
      @mousemove="scroll"
    >
      <slot name="items"> </slot>
    </div>

    <div class="rocketBottom" @click="$emit('clickedRocketBottom')">
      <div class="missionTitle">
        {{ bottomText }}
        <img v-if="showEditIcon" class="editButton" src="../MissionsSection/assets/icons/edit_icon.svg" alt="" />
      </div>

      <MissionTotalProgressCircular
        v-if="progress"
        class="missionProgress"
        :progress="progress"
        :team-progress="teamProgress"
      />
      <img class="missionBadge" :src="badgeImage" alt="" />
      <img class="rocketBottomImage" src="./assets/rocket-bottom.svg" alt="" />
      <div class="rocketFlameImageContainer">
        <img class="rocketFlameImage" src="./assets/rocket-flame.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import MissionTotalProgressCircular from '../MissionsSection/MissionTotalProgressCircular'

export default {
  components: {
    MissionTotalProgressCircular,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    badgeImage: {
      type: String,
      default: '',
    },
    progress: {
      type: Number,
      default: 0,
    },
    teamProgress: {
      type: Number,
      default: 0,
    },
    bottomText: {
      type: String,
      default: '',
    },
    showEditIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isScrolling: false,
      startY: 0,
      currentY: 0,
      scrollTop: 0,
      justScrolled: false,
    }
  },

  methods: {
    stopScroll() {
      this.isScrolling = false
      setTimeout(() => {
        this.justScrolled = false
      }, 10)
      window.removeEventListener('mouseup', this.stopScroll, false)
    },
    startScroll(event) {
      this.isScrolling = true
      this.startY = event.clientY
      this.scrollTop = this.$refs.scrollContainer.scrollTop
      window.addEventListener('mouseup', this.stopScroll, false)
    },
    scroll(event) {
      if (this.isScrolling) {
        this.justScrolled = true
        this.currentY = event.clientY
        this.$refs.scrollContainer.scrollTop = this.scrollTop + this.startY - this.currentY
      }
    },
  },
}
</script>

<style scoped lang="scss">
.rocketTop {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.5em;

  @media only screen and (max-width: 600px) {
    margin: 0 1.6vw;
    height: 13vw;
  }

  .rocketTopImage {
    position: absolute;
    opacity: 0.7;
    pointer-events: none;
  }
  .skillsTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    font-size: 1.15em;
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    color: #d6d4ef;
    cursor: pointer;
    z-index: 2;
    margin-top: 2.2em;

    @media only screen and (max-width: 600px) {
      font-size: 3.1vw;
      margin-top: 5.5vw;
    }
  }
}
.rocketBottom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10em;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    margin: 0 1.5vw;
    height: 25vw;
  }

  .missionTitle {
    font-size: 1.1em;
    position: absolute;
    top: 1.1em;
    opacity: 1;
    color: #d6ecff;

    @media only screen and (max-width: 600px) {
      font-size: 3.3vw;
      top: 3vw;
    }
  }

  .rocketBottomImage {
    position: absolute;
    opacity: 0.7;
    pointer-events: none;
  }

  .missionBadge {
    position: absolute;
    top: 3.9em;
    background-color: #4396ff;
    height: 6.5em;
    width: 6.5em;
    border-radius: 7em;
    pointer-events: none;

    @media only screen and (max-width: 600px) {
      height: 15vw;
      width: 15vw;
      border-radius: 20vw;
      top: 10.5vw;
    }
  }

  .rocketFlameImageContainer {
    position: absolute;
    top: 7.5em;
    display: flex;
    justify-content: center;
    width: 20em;
    height: 30em;
    overflow: hidden;
    pointer-events: none;
    opacity: 0.3;

    @media only screen and (max-width: 600px) {
      width: 50vw;
      height: 80vw;
      top: 16vw;
    }

    .rocketFlameImage {
      position: absolute;
      width: 6.5em;
      height: 9em;

      @media only screen and (max-width: 600px) {
        width: 15vw;
        height: 25vw;
      }
    }
  }
}

.braindatesContainer {
  height: 10em;
  width: 19em;
  overflow-y: auto;

  &.justScrolled {
    /deep/ > div {
      // NOTE: This is a hack to prevent click events from firing on items after scrolling
      pointer-events: none;
    }
  }

  @media only screen and (max-width: 600px) {
    height: 50vw;
    width: 50vw;
  }
}

.missionBadge {
  height: 5em;
  z-index: 2;
  @media only screen and (max-width: 600px) {
    height: 17vw;
  }
}

.editButton {
  width: 0.7em;
  height: 0.7em;
  color: white;
  margin-left: 0.5em;
  cursor: pointer;
  opacity: 0.8;

  @media only screen and (max-width: 600px) {
    margin-left: 1.5vw;
  }
}
</style>
