<template>
  <div class="MissionBraindates">
    <!-- <TeamMembersProgressDemo /> -->
    <h1 class="header">
      {{ $t('title1') }} <br />
      {{ $t('title2') }}
    </h1>
    <img class="missionsTopImage" src="../MissionsSection/assets/images/missions-top-illustration.svg" alt="" />
    <div class="missionsContainer">
      <SkillMissionBraindatesRow class="skillMissionBraindatesRow" />
      <HabitMissionBraindatesRow class="habitMissionBraindatesRow" />
    </div>
  </div>
</template>

<translations>

    title1: 'Conquer solar systems'
    title1_no: 'Erobre solsystemer'
    title2: 'with missions & teams'
    title2_no: 'med oppdrag & teams'
  </translations>

<script>
import HabitMissionBraindatesRow from './HabitMissionBraindatesRow'
import SkillMissionBraindatesRow from './SkillMissionBraindatesRow'
import TeamMembersProgressDemo from './TeamMembersProgressDemo'

export default {
  components: { HabitMissionBraindatesRow, SkillMissionBraindatesRow, TeamMembersProgressDemo },
}
</script>

<style lang="scss" scoped>
.MissionBraindates {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 4vw;
  }
}
.missionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.missionsTopImage {
  width: 44em;
  margin-left: -8em;
  margin-top: 1em;
  @media only screen and (max-width: 600px) {
    width: 105vw;
    margin-left: -10vw;
    margin-top: 3vw;
  }
}

.header {
  position: absolute;
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #bec2e5;
  top: 4.9em;
  width: 100vw;

  @media only screen and (max-width: 600px) {
    font-size: 4vw;
    margin-top: 0;
  }
}
.skillMissionBraindatesRow {
  margin-right: 1em;

  @media only screen and (max-width: 600px) {
    margin-right: 0;
  }
}
.habitMissionBraindatesRow {
  margin-left: 1em;

  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
}
</style>
