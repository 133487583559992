<template>
  <ItemBase
    class="ItemMemoryiqTheory"
    :label="$t('label')"
    :title="$t('title')"
    :icon="icon"
    :progress="$attrs.progress"
    :pulse="$attrs.pulse"
    @click="open()" />
</template>

<translations>
  title: 'Test<br> your memory'
  title_no: 'Test<br> hukommelsen'
  label: 'MemoryIQ'
  label_no: 'MemoryIQ'
</translations>

<script>
import ItemBase from './ItemBase';
import icon from './icons/memoryiq.svg';

export default {
    components: { ItemBase },
    created() {
        this.icon = icon;
    },
    methods: {
        open() {
            this.$router.push({
                name: 'MemoryIQView'
            });
        }
    }
};
</script>
