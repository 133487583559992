<template>
  <ThumbBase
    class="LanguageItem"
    :image="image"
    :title="languageNameNative(languageId)"
    :subtitle="$t(`memolanguage.languageLabels.${languageId}`)"
    @click="tryGotoMemolanguage()">

    <img class="flagIcon" :src="{image}" alt="">
  
  </ThumbBase>
</template>

<script>
import ThumbBase from '../../../components/ThumbBase.vue';

const images = require.context('./images/', true, /\.(png|jpg)$/);

export default {
    components: { ThumbBase },
    props: {
        languageId: {
            type: String,
            required: true
        }
    },
    computed: {
        image() {
            try {
                return images(`./${this.languageId}-thumbnail.png`);
            } catch (err) {
                return null;
            }
        },
    },
    methods: {
        gotoMemolanguage() {
            this.$router.push(`/memolanguage/${this.languageId}/home`);
        },
        tryGotoMemolanguage() {
            if (this.$store.getters['moduleAuth/canAccessOldMemolanguage']) {
                this.showBetaPopup();
            } else {
                this.gotoMemolanguage();
            }
        },
        languageNameNative(languageId){
            if(languageId==='english'){
                return 'English';
            } else if(languageId==='german'){
                return 'Deutsch';
            } else if(languageId==='norwegian'){
                return 'Norsk';
            } else if(languageId==='italian'){
                return 'Italiano';
            } else if(languageId==='french'){
                return 'Français';
            } else if(languageId==='portuguese'){
                return 'Português';
            } else if(languageId==='spanish'){
                return 'Español';
            }
        }
    }  
};
</script>

<style lang="scss" scoped>
    .LanguageItem {
        /deep/ {
            min-height: 0;
        }
    }
</style>
  