<template>
  <div class="TeamItem">
    <img v-if="selected" class="eyeIcon" src="./assets/icons/eye-icon.svg" alt="" />
    <img class="rankImage" :class="{ selected: selected }" :src="getTeamIcon()" alt="" />

    <div class="teamName" :class="{ isSelected: selected }">{{ team.teamName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    team: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTeamIcon() {
      var tag = ''
      if (this.team.tags !== null) {
        tag = this.team.tags[0]
      }

      if (tag === 'Family') {
        return require('./assets/icons/family-team-icon.svg')
      } else if (tag === 'Friends') {
        return require('./assets/icons/friends-team-icon.svg')
      } else if (tag === 'Work') {
        return require('./assets/icons/work-team-icon.svg')
      } else if (tag === 'Solo') {
        return require('./assets/icons/solo-icon.svg')
      } else {
        return require('./assets/icons/other-team-icon.svg')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.TeamItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  // background: #181e53;
  text-align: center;
  padding-top: 1.4em;
  flex: 1;
  overflow: hidden;

  border-radius: 1.1em;
  border: 2px solid #19296100;
  margin: 0em 0.8em;
  cursor: pointer;

  height: 10.8em;
  width: 8em;
  min-width: 8em;
  max-width: 8em;

  @media only screen and (max-width: 600px) {
    height: 31.5vw;
    width: 24vw;
    min-width: 24vw;
    max-width: 24vw;
    margin: 0 1vw;
  }

  @media (max-width: 1200px) {
    // width: 10.5em;
  }

  &.opacitated {
    opacity: 0.4;
  }
  .eyeIcon {
    display: none;
    position: absolute;
    top: 1em;
    margin-left: auto;
    margin-right: auto;
    // right: 1em;
    width: 1.2em;
    height: auto;
  }
  .rankName {
    font-size: 1.1em;
    padding-bottom: 0.3em;
  }
  .rankBraindates {
    font-size: 1em;
    opacity: 0.7;
  }

  .rankImage {
    width: 6em;
    height: 6em;
    border-radius: 6em;
    margin-bottom: 0.6em;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    pointer-events: none;

    &.selected {
      outline: 2px solid #4763c6;
      border: 3px solid #16134a;
    }

    $pulseDuration: 1.5s;
    &.selected {
      animation: pulse-animation2 $pulseDuration infinite cubic-bezier(0.66, 0, 0, 1);

      box-shadow: 0 0 0 0 #3f6bb4; // $standardButtonColor;

      @keyframes pulse-animation2 {
        to {
          box-shadow: 0 0 0 0.7em rgba($standardButtonColor, 0);
        }
      }
    }

    @media only screen and (max-width: 600px) {
      width: 15vw;
      height: 15vw;
    }
  }
  .teamName {
    width: 100%;
    font-size: 1.1em;
    color: #7fb2f2;
    // background-color: #1b2765;
    padding-top: 0.5em;
    padding-bottom: 1em;
    // padding-left: 1em;
    // padding-right: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    // border: 1px solid red;
    @media only screen and (max-width: 600px) {
      font-size: 3.6vw;
      padding-top: 2vw;
      padding-bottom: 3vw;
      // padding-left: 3vw;
      // padding-right: 3vw;
    }

    opacity: var(--labelOpacity, 1);
  }
}
</style>
