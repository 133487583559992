<template>
  <div class="SectionBase">
    <div class="header">
      <slot name="icon" />
      <div class="titleContainer">
        <div class="title">
          {{ title }}
        </div>
        <slot name="titleExtra" />
      </div>
      <div
        v-if="text"
        class="text">
        {{ text }}
      </div>
      <slot name="headerExtra" />
    </div>

    <slot name="missionBadges" />

    <FullWidthHorizontalScroll>
      <div class="items">
        <slot name="items" />
      </div>
    </FullWidthHorizontalScroll>
  </div>
</template>

<script>
import FullWidthHorizontalScroll from './FullWidthHorizontalScroll';

export default {
    components: { FullWidthHorizontalScroll },
    props: {
        title: {
            type: String,
            // required: true
        },
        text: {
            type: String
        }
    }
};
</script>

<style lang="scss" scoped>
.SectionBase {
}
.header {
    // display: flex; // this?
    // align-items: center; // this?
}
.titleContainer {
    display: flex; // this?
    align-items: center; // this?
}
.title {
    font-size: $dashboard-sectionTitleFontSize;
    color: white;
}

.info {
    color: rgba(white, 0.5);
    margin-left: 1em;
}

.text {
    margin-top: 0.1em;
    color: rgba(white, 0.7);
    line-height: 1.5em;
}

.FullWidthHorizontalScroll {
    margin-top: 0.5em;
}

.items {
    display: flex;

    /deep/ & > * {
        flex-shrink: 0;

        &:not(:first-child) {
            margin-left: $dashboard-itemGapWidth;
        }
    }
}
</style>
