<template>
  <div class="AvatarRive">
    <canvas ref="beltCanvas" class="riveContainer" width="170px" height="170px" />
  </div>
</template>


<script>
const rive = require("rive-js");
import RiveFile from '@/assets/rive/profile-icon-belt.riv'

export default {

  data() {
    return {
      r: null,
      inputs: null,
    };
  },

  mounted() {
    if(this.belt.current!=null){
      this.setRive();
    }
  },

  computed: {
    belt() {
      return this.$store.getters['moduleApp/belt'];
    },
  },

  beforeDestroy(){
    if(this.r!=null){
     this.r.stop();
     this.r.unsubscribeAll();
     this.r = null; 
    }
  },

  methods: {
    setRive(){
      this.r = new rive.Rive({
          src: RiveFile,
          canvas: this.$refs.beltCanvas,
          stateMachines: 'belt_states',
          autoplay: true,
          layout: new rive.Layout({fit: 'fill', alignment: 'center'}),
          onload: () => {
            this.inputs = this.r.stateMachineInputs('belt_states');
            this.beltNumber(this.belt.current.index);
            this.beltAnimation(false);
            this.hideIcon(true);
          },
      }); 
    },
    beltNumber(v){
      this.inputs[0].value = v;
    },
    beltAnimation(v){
     this.inputs[1].value = v;
    },
    hideIcon(v){
     this.inputs[2].value = v;
    }
  },
  
};
</script>

<style scoped lang="scss">

.riveContainer {
  @media (max-width: 620px) {
    width: 36vw;
  }
}

</style>
