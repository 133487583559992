<template>
  <BraindateItemBase
    class="HabitMissionBraindateItem"
    :title="sourceLanguage === 'no' ? item.title_no : item.title"
    :subtitle="$t('star') + ' ' + item.lifeskillId"
    :icon="getMissionBraindateIcon(item.type, item.id)"
    :extra-icon="item.doneToday ? checkmarkIcon : ''"
    @click.native="goToBraindate(item)"
  />
</template>

<translations>
  star: 'Star'
  star_no: 'Stjerne'
</translations>

<script>
const gameBraindateIcons = require.context('@/assets/images/memogenius/game/icon', true, /\.(png)$/)
const mentalhygieneBraindateIcons = require.context('../MissionsSection/assets/icons', true, /\.(png)$/)
import BraindateItemBase from './BraindateItemBase.vue'
import checkmarkIcon from '../MissionsSection/assets/icons/checkmark.svg'

export default {
  components: {
    BraindateItemBase,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sourceLanguage() {
      return this.$store.getters['moduleApp/locale']
    },
    checkmarkIcon() {
      return checkmarkIcon
    },
  },
  methods: {
    getMissionBraindateIcon(type, braindateId) {
      if (type === 'mentalHygiene') {
        return mentalhygieneBraindateIcons(`./${braindateId}-icon.png`)
      } else {
        return gameBraindateIcons(`./${braindateId}.png`)
      }
    },

    goToBraindate(item) {
      if (this.justScrolled) {
        return
      }

      if (item.type === 'mentalHygiene') {
        this.$router.push({
          name: 'MentalHygiene',
          params: {
            setInitialTab: true,
            stepNumber: item.stepNumber,
          },
        })
      } else if (item.type === 'game') {
        this.$store.commit('moduleApp/setGameExitTo', this.$route.path)
        this.$router.replace({
          name: 'MemogeniusGame-Levels',
          params: {
            gameId: item.id,
          },
        })
      }
    },
  },
}
</script>
