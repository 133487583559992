<template>
  <div class="LanguageRow">
    <FullWidthHorizontalScroll class="FullWidthHorizontalScroll">
      <div class="items">
        <LanguageItem
          v-for="languageId of languages"
          :key="languageId"
          class="GameItem"
          :language-id="languageId" />
      </div>
    </FullWidthHorizontalScroll>
  </div>
</template>
  
  
  <script>
  import FullWidthHorizontalScroll from '../FullWidthHorizontalScroll.vue';
  import LanguageItem from './LanguageItem';
  
  export default {
      components: { FullWidthHorizontalScroll, LanguageItem },
      computed: {
          languages() {
            return this.$store.getters['moduleApp/availableLanguages'];
          }
      }
  };
  </script>
  
  <style lang="scss" scoped>
  .LanguageRow {
    overflow-x: scroll;

    @media (max-width: 620px) {
      width: 100vw;
    }
    @media (min-width: 620px) and (max-width: 1200px) {
      width: 100vw;
    }
  }
  .GameItem {

      width: (180em / 15);
      
      /deep/ .card {
          height: (183em / 15);
      }
  }
  
  .items {
      display: flex;
      margin-left: 1em;

      @media (min-width: 620px) and (max-width: 1200px) {
        margin-left: 4vw;
      }
      
      /deep/ & > * {
          flex-shrink: 0;
  
          &:not(:first-child) {
            margin-left: 0.7em;              
          }
      }
  }
  </style>