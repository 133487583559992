<template>
  <div
    v-if="publishedMemoransierGames.length"
    class="MemoransierGamesSection">
    <div>
      <MemoransierGameItem
        v-for="game of publishedMemoransierGames"
        :key="game.id"
        class="MemoransierGameItem"
        v-bind="game" />
    </div>
  </div>
</template>

<script>
import MemoransierGameItem from './MemoransierGameItem';

export default {
    components: { MemoransierGameItem },
    computed: {
        publishedMemoransierGames() {
            return this.$store.getters['moduleMemoransier/publishedGames'];
        }
    },
    mounted() {
        this.$store.dispatch('moduleMemoransier/refresh');
    }
};
</script>

<style lang="scss" scoped>
.MemoransierGamesSection {
    padding-bottom: 1em;
}

.MemoransierGameItem {
    &:not(:first-child) {
        margin-top: 1.5em;
    }
}
</style>
