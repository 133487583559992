<template>
  <div class="VideosRow">
    <FullWidthHorizontalScroll v-if="isMobile" class="FullWidthHorizontalScroll">
      <div class="items">
        <VideoItem
          v-for="{ videoId, lifeskillId, title, duration, thumbnail } of interestsVideos"
          :key="videoId"
          :video-id="videoId"
          :lifeskill-id="lifeskillId"
          :video-title="title"
          :video-duration="duration"
          :video-thumbnail="thumbnail" />
      </div>
    </FullWidthHorizontalScroll>

    <carousel
      v-if="!isMobile"
      :navigate-to="[0, false]"
      :per-page="1"
      :pagination-enabled="true"
      :navigation-enabled="false"
      :navigation-click-target-size="20"
      :pagination-active-color="'white'"
      :pagination-color="'rgb(55 61 101)'"
      class="carousel">
      <slide v-for="(vchunk, index) in chunkedVideos" :key="index" class="item-wrapper">
        <div class="videosRow"> 
          <VideoItem
            v-for="{ videoId, lifeskillId, title, duration, thumbnail } of vchunk"
            :key="videoId"
            :video-id="videoId"
            :lifeskill-id="lifeskillId"
            :video-title="title"
            :video-duration="duration"
            :video-thumbnail="thumbnail" /> 
        </div>
      </slide>
    </carousel>
  </div>
</template>
    
    
<script>
    import VideoItem from './VideoItem';
    import { Carousel, Slide } from 'vue-carousel';
    import FullWidthHorizontalScroll from '../FullWidthHorizontalScroll.vue';
    
    export default {
        components: { VideoItem, Carousel, Slide, FullWidthHorizontalScroll },
        computed: {
            interestsVideos() {
              return this.$store.getters['moduleApp/interestsVideos'] || [];
            },
            chunkedVideos(){
                let chunkSize = 6;
                return this.chunk(this.interestsVideos, chunkSize);
            },
            isMobile() {
                if(/Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true;
                } else {
                return false;
                }
            },
        },
        methods: {
            chunk(arr, chunkSize) {
              if (chunkSize <= 0) throw "Invalid chunk size";
              var R = [];
              for (var i=0,len=arr.length; i<len; i+=chunkSize)
                R.push(arr.slice(i,i+chunkSize));
              return R;
            }
        }
    };
</script>
    
<style lang="scss" scoped>
    .VideosRow {
      width: 76.2em;
      @media (max-width: 620px) {
        overflow-x: scroll;
        width: 100vw;
      }
      @media (min-width: 620px) and (max-width: 1200px) {
        overflow-x: scroll;
        width: 100vw;
      }
    }
    .items {
        display: flex;
        margin-left: 1em;

        @media (min-width: 620px) and (max-width: 1200px) {
          margin-left: 3vw;    
        }
        
        /deep/ & > * {
            flex-shrink: 0;
    
            &:not(:first-child) {
              margin-left: 0.7em;              
            }
        }
    }

    .videosRow {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .carousel {
        margin-top: 1.3em;
        @media (min-width: 620px) and (max-width: 1200px) {
          margin-top: 0;
          margin-left: 3vw;
        }
        /deep/ .VueCarousel-pagination {
            .VueCarousel-dot-container{
            margin-top: 0 !important;
                @media only screen and (max-width: 620px) {
                  margin-top: -3vw !important;
                }
                @media (min-width: 620px) and (max-width: 1200px) {
                  margin-top: 0 !important;
                }
            }
        }
    }
</style>