var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"VideosRow"},[(_vm.isMobile)?_c('FullWidthHorizontalScroll',{staticClass:"FullWidthHorizontalScroll"},[_c('div',{staticClass:"items"},_vm._l((_vm.interestsVideos),function(ref){
var videoId = ref.videoId;
var lifeskillId = ref.lifeskillId;
var title = ref.title;
var duration = ref.duration;
var thumbnail = ref.thumbnail;
return _c('VideoItem',{key:videoId,attrs:{"video-id":videoId,"lifeskill-id":lifeskillId,"video-title":title,"video-duration":duration,"video-thumbnail":thumbnail}})}),1)]):_vm._e(),(!_vm.isMobile)?_c('carousel',{staticClass:"carousel",attrs:{"navigate-to":[0, false],"per-page":1,"pagination-enabled":true,"navigation-enabled":false,"navigation-click-target-size":20,"pagination-active-color":'white',"pagination-color":'rgb(55 61 101)'}},_vm._l((_vm.chunkedVideos),function(vchunk,index){return _c('slide',{key:index,staticClass:"item-wrapper"},[_c('div',{staticClass:"videosRow"},_vm._l((vchunk),function(ref){
var videoId = ref.videoId;
var lifeskillId = ref.lifeskillId;
var title = ref.title;
var duration = ref.duration;
var thumbnail = ref.thumbnail;
return _c('VideoItem',{key:videoId,attrs:{"video-id":videoId,"lifeskill-id":lifeskillId,"video-title":title,"video-duration":duration,"video-thumbnail":thumbnail}})}),1)])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }