<template>
  <ItemBase
    class="ItemSetupDailyGoal"
    :label="$t('label')"
    :title="$t('title')"
    :icon="icon"
    :pulse="$attrs.pulse"
    :progress="$attrs.progress"
    @click="open()" />
</template>

<translations>
  title: 'Set your<br> daily goal'
  title_no: 'Sett ditt<br> daglige mål'
  label: 'Brain dates'
  label_no: 'Hjernedates'
</translations>

<script>
import ItemBase from './ItemBase';
import icon from './icons/target.svg';
import { EventBus } from '@/event-bus';

export default {
    components: { ItemBase },
    created() {
        this.icon = icon;
    },
    methods: {
        open() {
          EventBus.$emit('request-open-daily-goal-settings');
        }
    }
};
</script>
