<template>
  <SectionBase
    class="GettingStartedSection"
    :title="$t('title')">
    <template #headerExtra>
      <div class="progress">
        <div class="progressText">
          {{ numberOfItemsCompleted }} {{ $t('general.of') }} {{ items.length }}
        </div>
        <div class="progressBar">
          <div
            class="progressBarIndicator"
            :style="{ width: `${totalProgress * 100}%` }" />
        </div>
      </div>
      <div
        v-if="hasFocus"
        class="exitFocusButton --global-clickable"
        @click="exitFocus()">
        Exit
      </div>
    </template>

    <template #items>
      <component
        :is="item.component"
        v-for="(item, i) of items"
        :key="item.id"
        class="gettingStartedItem"
        :progress="item.progress"
        :locked="item.locked"
        :pulse="i === nextItemIndex" />
    </template>
  </SectionBase>
</template>

<translations>
  title: 'Get started with your first belt'
  title_no: 'Kom i gang med ditt første belte'
  text: 'Superfast learning with fun memory techniques'
  text_no: 'Lynrask læring med morsomme husketeknikker'
</translations>

<script>
import SectionBase from '../SectionBase';
import ItemSetupDailyGoal from './ItemSetupDailyGoal';
import ItemMemoryiqTheory from './ItemMemoryiqTheory';
import ItemFirstVideo from './ItemFirstVideo';
import ItemSelectFavorites from './ItemSelectFavorites';
import { mapGetters } from 'vuex';

export default {
    components: { SectionBase },
    computed: {
      ...mapGetters({
        totalProgress: 'moduleApp/gettingStartedTotalProgress',
        gettingStartedItems: 'moduleApp/gettingStartedItems',
      }),
        items() {
          return this.gettingStartedItems
            .map(({ progress, id, locked }) => ({
                id,
                progress,
                locked: false,
                component: {
                    setDailyGoal: ItemSetupDailyGoal,
                    memoryiq: ItemMemoryiqTheory,
                    firstVideo: ItemFirstVideo,
                    selectFavorites: ItemSelectFavorites
                }[id]
            }));
        },
        nextItemIndex() {
            return this.items.findIndex(item => item.progress < 1);
        },
        numberOfItemsCompleted() {
            return this.items.filter(item => item.progress === 1).length;
        },
        hasFocus() {
          return this.$store.getters['moduleApp/gettingStartedHasFocus'];
        }
    },
    methods: {
        exitFocus() {
            this.$store.dispatch('moduleApp/markDidExitGettingStartedFocus');
        }
    }
};
</script>

<style lang="scss" scoped>
.GettingStartedSection {
    position: relative;
}

.gettingStartedItem {
  cursor: pointer;
}

.progress {
    display: flex;
    align-items: center;
    margin-top: 1em;
    justify-content: flex-start;
}
.progressText {
    color: rgba(white, 0.5);
}
.progressBar {
    flex: 1;
    margin-left: 1em;
    max-width: 7em;

    background-color: rgba(black, 0.2);
    border-radius: 2em;
    width: 100%;
    overflow: hidden;
}
.progressBarIndicator {
    background-color: $standardButtonColor;
    width: 30%;
    height: 1em;
}

.exitFocusButton {
    background-color: rgba(black, 0.2);
    color: rgba(white, 0.8);
    padding: 0.5em 0.8em;
    border-radius: 0.4em;

    position: absolute;
    top: 0;
    right: 0;
}

@include media('<mobile') {
    .exitFocusButton {
        top: 3.6em;
    }
}
</style>
