<template>
  <div class="GameLanguageVideoSection">
    <div class="title"> {{ $t('title') }} </div>
    <img class="baseCampImage" :src="getBaseCampImage()" alt="" />

    <div class="gamesAndLanguageContainer">
      <div class="gamesAndLanguageTabs">
        <img class="joystickIcon" src="./images/joystick-icon.svg" alt="" />
        <div
          class="memoryLanguageTitle memoryTitle"
          :class="{ selected: memorySelected }"
          @click="memorySelected = true"
        >
          {{ $t('memory') }}
        </div>
        <div class="memoryLanguageTitle" :class="{ selected: !memorySelected }" @click="memorySelected = false">
          {{ $t('language') }}
        </div>
      </div>
      <GamesRow v-if="memorySelected" class="gamesRow" />
      <LanguageRow v-if="!memorySelected" class="gamesRow" />
    </div>

    <div class="videosContainer">
      <div class="videosTab">
        <div class="iconAndInterestsContainer">
          <img class="videoIcon" src="./images/videos-icon.svg" alt="" />
          <div class="interestsTitle" @click="editInterests()">
            {{ $t('fromInterests') }} <img class="editIcon" src="./images/edit-icon.svg" alt="" />
          </div>
        </div>
        <div class="viewAllTitle" @click="goToVideoPage()"> {{ $t('viewAll') }} </div>
      </div>
      <VideosRow class="gamesRow" />
    </div>
  </div>
</template>

<translations>
    title: 'Have Fun & Get Inspired'
    title_no: 'Ha det gøy & bli inspirert'
    memory: 'Memory'
    memory_no: 'Hukommelse'
    language: 'Languages'
    language_no: 'Språk'
    fromInterests: 'From your interests'
    fromInterests_no: 'Fra dine interesser'
    viewAll: 'View all'
    viewAll_no: 'Se alle'
</translations>

<script>
import baseCampImageMobile from './images/base-camp-image-mobile.svg'
import baseCampImageDesktop from './images/base-camp-image-desktop.svg'
import GamesRow from './GamesRow.vue'
import LanguageRow from './LanguageRow.vue'
import VideosRow from './VideosRow.vue'

export default {
  components: { GamesRow, LanguageRow, VideosRow },
  data() {
    return {
      memorySelected: true,
    }
  },
  computed: {
    isMobile() {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    getBaseCampImage() {
      if (this.isMobile) {
        return baseCampImageMobile
      } else {
        return baseCampImageDesktop
      }
    },
    editInterests() {
      this.$router.push({
        name: 'FollowLifeskillView',
      })
    },
    goToVideoPage() {
      this.$router.push({ name: 'MemolifeTvView' })
    },
  },
}
</script>

<style lang="scss" scoped>
.GameLanguageVideoSection {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; // to crop the image

  @media (max-width: 620px) {
    margin-top: 10vw;
  }
}

.title {
  position: absolute;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #d6d4ef;
  top: 6em;
  font-size: 1.5em;

  @media (max-width: 620px) {
    top: 20vw;
    font-size: 4vw;
  }
}
.baseCampImage {
  position: absolute;
  width: 100em;

  margin-right: 3em;
  @media (max-width: 620px) {
    margin-right: 0;
    width: 100vw;
  }
}
.gamesAndLanguageContainer {
  margin-top: 25em;

  @media (max-width: 620px) {
    margin-top: 70vw;
  }
  .gamesAndLanguageTabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1em;
    margin-bottom: 1em;

    @media (max-width: 620px) {
      margin-left: 3vw;
      margin-bottom: 3vw;
    }
    @media (min-width: 620px) and (max-width: 1200px) {
      margin-left: 4vw;
      margin-bottom: 1vw;
    }
    .joystickIcon {
      width: 2em;
      height: auto;

      @media (max-width: 620px) {
        width: 8vw;
        height: auto;
      }
    }
    .memoryLanguageTitle {
      font-size: 1em;
      margin-left: 0.5em;
      padding: 0.7em 1.3em;

      z-index: 2;
      cursor: pointer;

      @media (max-width: 620px) {
        font-size: 3.7vw;
        margin-left: 1vw;
        padding: 2vw 3vw;
      }

      &.selected {
        background-color: #454899;
        padding: 0.7em 1.3em;
        border-radius: 3em;

        @media (max-width: 620px) {
          padding: 2vw 3vw;
          border-radius: 6vw;
        }
      }
    }
    .memoryTitle {
      margin-left: 2em;
      @media (max-width: 620px) {
        margin-left: 6vw;
      }
    }
  }
}

.videosContainer {
  margin-top: 1em;
  margin-bottom: 7em;
  @media (max-width: 620px) {
    margin-top: 4vw;
  }
  @media (min-width: 620px) and (max-width: 1200px) {
    margin-top: 3vw;
  }
  .videosTab {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.7em;
    margin-bottom: 0.2em;

    @media (max-width: 620px) {
      margin-left: 3vw;
      margin-bottom: 4vw;
    }
    @media (min-width: 620px) and (max-width: 1200px) {
      margin-left: 4vw;
      margin-bottom: 2vw;
    }

    .iconAndInterestsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .videoIcon {
        width: 2.1em;
        height: auto;

        @media (max-width: 620px) {
          width: 8vw;
        }
      }

      .interestsTitle {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 1em;
        font-size: 1em;
        cursor: pointer;
        z-index: 2;

        @media (max-width: 620px) {
          margin-left: 4vw;
        }
      }
      .editIcon {
        width: 0.8em;
        height: 0.8em;
        color: white;
        margin-left: 0.7em;
        opacity: 0.8;

        @media (max-width: 620px) {
          width: 6vw;
          margin-left: 2vw;
        }
      }
    }

    .viewAllTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1em;
      margin-right: 0.2em;
      color: #4590f8;
      cursor: pointer;
      z-index: 2;

      @media (max-width: 620px) {
        margin-right: 4vw;
      }
      @media (min-width: 620px) and (max-width: 1200px) {
        margin-right: 4vw;
      }
    }
  }
}
</style>
