<template>
  <ItemBase
    class="ItemFirstVideo"
    :label="$t('label')"
    :title="$t('title')"
    :icon="icon"
    :progress="$attrs.progress"
    :pulse="$attrs.pulse"
    @click="open()" />
</template>

<translations>
  title: 'Unleash<br> your superpowers'
  title_no: 'Slipp løs<br> dine superkrefter'
  label: 'Video'
</translations>

<script>
import ItemBase from './ItemBase';
import icon from './icons/video.svg';
import settings from '@/settings';

export default {
    components: { ItemBase },
    created() {
        this.icon = icon;
    },
    methods: {
        open() {
            this.$router.push({
                name: 'DashboardVideoView',
                params: {
                    videoId: settings.onboardingVideoId,
                    lifeskillId: '10'
                }
            });
        }
    }
};
</script>
