<template>
  <BraindateItemBase
    class="SkillMissionBraindateItem"
    :title="getBraindateTitle(item)"
    :subtitle="$t('star') + ' ' + item.lifeskillId"
    :icon="getBraindateIcon(item)"
    :extra-icon="item.locked ? lockIcon : ''"
    @click.native="goToBraindate(item)"
  />
</template>

<translations>
  star: 'Star'
  star_no: 'Stjerne'
</translations>

<script>
import BraindateItemBase from './BraindateItemBase.vue'
import lockIcon from './assets/braindateLockIcon.svg'
const SkillBadgeImages = require.context('./assets', true, /\.(svg)$/)

export default {
  components: {
    BraindateItemBase,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sourceLanguage() {
      return this.$store.getters['moduleApp/locale']
    },
    lockIcon() {
      return lockIcon
    },
  },
  methods: {
    goToBraindate(item) {
      if (this.justScrolled) {
        return
      }

      if (item.type === 'journeyQuiz' && !item.locked) {
        this.$router.push({
          name: 'StepGroupQuizView',
          params: {
            courseId: item.courseId,
            stepGroupId: item.id,
          },
        })
      } else if (item.type === 'scenario' && !item.locked) {
        this.$router.push({
          name: 'ScenarioView',
          params: {
            id: item.id,
            lifeskillId: item.lifeskillId,
            stepId: item.stepId,
          },
        })
      } else if (item.type === 'mentalHygiene') {
        this.$router.push({
          name: 'MentalHygiene',
          params: {
            setInitialTab: true,
            stepNumber: item.stepNumber,
          },
        })
      } else if (item.type === 'video' && !item.locked) {
        this.$router.push({
          name: 'DashboardLifeskillView',
          params: {
            lifeskillId: item.lifeskillId,
            tabName: 'videos',
          },
        })
      } else if (item.type === 'read' || item.type === 'introduction') {
        this.$router.push({
          name: 'Course-ActivityTransition',
          params: {
            courseId: item.courseId,
            stepId: item.stepId,
            activityName: 'read',
          },
        })
      } else if ((item.type === 'memo' && !item.locked) || (item.type === 'memo2' && !item.locked)) {
        this.$router.push({
          name: 'Course-ActivityTransition',
          params: {
            courseId: item.courseId,
            stepId: item.stepId,
            activityName: item.type === 'memo' ? 'memorize' : 'memorize2',
          },
        })
      } else if ((item.type === 'quiz' && !item.locked) || (item.type === 'quiz2' && !item.locked)) {
        this.$router.push({
          name: 'Course-ActivityTransition',
          params: {
            courseId: item.courseId,
            stepId: item.stepId,
            activityName: item.type === 'quiz' ? 'quiz' : 'quiz2',
          },
        })
      }
    },
    getBraindateIcon(item) {
      if (item.type === 'introduction') {
        return SkillBadgeImages(`./${item.category}/braindateIntroductionIcon.svg`)
      } else if (item.type === 'read') {
        return SkillBadgeImages(`./${item.category}/braindateReadIcon.svg`)
      } else if (item.type === 'memo') {
        return SkillBadgeImages(`./${item.category}/braindateMemoIcon.svg`)
      } else if (item.type === 'memo2') {
        return SkillBadgeImages(`./${item.category}/braindateMemo2Icon.svg`)
      } else if (item.type === 'scenario' || item.type === 'mentalHygiene') {
        return SkillBadgeImages(`./${item.category}/braindateScenarioIcon.svg`)
      } else if (item.type === 'video') {
        return SkillBadgeImages(`./${item.category}/braindateVideoIcon.svg`)
      } else {
        return SkillBadgeImages(`./${item.category}/braindateQuizIcon.svg`)
      }
    },
    getBraindateTitle(item) {
      if (item.type === 'mentalHygiene') {
        if (this.sourceLanguage === 'no') {
          return item.title_no
        } else {
          return item.title
        }
      } else {
        return item.title
      }
    },
  },
}
</script>
