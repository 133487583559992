<template>
  <RocketContainer
    class="SkillMissionBraindatesRow"
    :title="$t('skills')"
    :badge-image="getMissionBadgeImage()"
    :progress="missionProgress"
    :team-progress="missionProgress"
    :bottom-text="selectedMissionName"
    :show-edit-icon="!!selectedMission"
    @clickedRocketBottom="expandMissions"
  >
    <template v-if="selectedMission" #items>
      <div v-if="selectedMission && !upNextMissionBraindates.length" class="dailyDoneTitle">{{ $t('allDone') }}</div>

      <SkillMissionBraindateItem v-for="(item, i) of upNextMissionBraindates" :key="i" :item="item" />

      <DummyItem v-if="upNextMissionBraindates.length < 2" />
      <DummyItem v-if="upNextMissionBraindates.length < 3" />
      <DummyItem v-if="upNextMissionBraindates.length < 4" />
    </template>

    <template v-else #items>
      <DummyItem>
        <div class="chooseTitle">
          {{ $t('choose1') }} <br />
          {{ $t('choose2') }}
        </div>
      </DummyItem>
      <DummyItem @click.native="expandMissions" />
      <DummyItem @click.native="expandMissions" />
    </template>
  </RocketContainer>
</template>

<translations>
    done: 'Done'
    done_no: 'Ferdig'

    skills: 'SKILLS'
    skills_no: 'FERDIGHETER'

    choose: 'Choose mission:'
    choose_no: 'Velg oppdrag:'
    todaysBraindates: "Today's brain dates:"
    todaysBraindates_no: 'Dagens hjernedates:'
    
    allDone: "You're done with today's brain dates"
    allDone_no: 'Du er ferdig med dagens hjernedates'

    choose1: Get your
    choose1_no: Få dine 
    choose2: daily brain dates
    choose2_no: daglige hjernedates

    ChooseSolo: 'Choose solo mission'
    ChooseSolo_no: 'Velg solo-oppdrag'
    ChooseTeam: 'Choose team mission'
    ChooseTeam_no: 'Velg team-oppdrag'
  </translations>

<script>
import { getSkillMissionById } from '@/store/modules/module-app/missions.js'
import SkillsMissionSectionPopup from '../MissionsSection/SkillsMissionSectionPopup'
import RocketContainer from './RocketContainer.vue'
import SkillMissionBraindateItem from './SkillMissionBraindateItem.vue'
import DummyItem from './DummyItem.vue'

const SkillIcons = require.context('../MissionsSection/assets/images/', true, /\.(svg|png)$/)

export default {
  components: { RocketContainer, SkillMissionBraindateItem, DummyItem },
  data() {
    return {
      mission: 'vekstkultur',
    }
  },
  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    sourceLanguage() {
      return this.$store.getters['moduleApp/locale']
    },
    upNextMissionBraindates() {
      return this.$store.getters['moduleApp/upNextMissionBraindates']
    },
    missionId() {
      return this.$store.getters['moduleApp/mission']()
    },
    selectedMission() {
      if (this.selectedTeamId === '0') {
        return getSkillMissionById(this.missionId)
      } else {
        const missionId = this.$store.getters['moduleApp/selectedTeamSkillMission']
        return getSkillMissionById(missionId)
      }
    },
    selectedMissionName() {
      if (!this.selectedMission) {
        if (this.selectedTeamId === '0') {
          return this.$t('ChooseSolo')
        } else {
          return this.$t('ChooseTeam')
        }
      } else {
        if (this.sourceLanguage === 'no') {
          return this.selectedMission.name //add _no when translations avail...
        } else {
          return this.selectedMission.name
        }
      }
    },
    missionProgress() {
      const lifeskillIds = this.selectedMission ? this.selectedMission.lifeskills : []
      if (!lifeskillIds.length) {
        return 0
      }
      var missionProgress = 0
      for (const i in lifeskillIds) {
        const stats = this.$store.getters['moduleApp/getLifeskillStats'](lifeskillIds[i])
        missionProgress = missionProgress + stats.progress
      }

      return missionProgress / lifeskillIds.length
    },
  },
  mounted() {
    this.$store.dispatch('moduleApp/loadAllVideos')
    this.$store.dispatch('moduleApp/loadVideosInProgress')
    const mission = this.$store.getters['moduleApp/mission']() || this.mission
    this.mission = mission
    const selectedMission = getSkillMissionById(mission)
    if (selectedMission) {
      selectedMission.lifeskills.forEach(lifeskillId => {
        const lifeskill = this.$store.getters['moduleApp/getLifeskillById'](lifeskillId)
        this.$store.dispatch('scenario/loadData', lifeskill.masterCourseId)
      })
    }
  },
  methods: {
    expandMissions() {
      this.$modal.open({
        component: SkillsMissionSectionPopup,
        target: 'app-modal',
      })
    },

    getMissionBadgeImage() {
      if (this.selectedMission) {
        return SkillIcons(`./${this.selectedMission.id}-circular.svg`)
      } else {
        return SkillIcons('./not-selected-circular.png')
      }
    },
  },
}
</script>

<style scoped lang="scss">
.dailyDoneTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: 1.3em;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #d6d4ef;
  cursor: pointer;
  margin-top: 1em;
  margin-bottom: 0.5em;

  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
    margin-bottom: 0;
    margin-left: 2vw;
    margin-top: 5vw;
  }
}
</style>
