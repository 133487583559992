<template>
  <div class="AuraBeltSection">

    <div v-if="!isMobile" class="desktopView">
      <FullWidthHorizontalScroll>
        <div class="--global-clickable" @click="gotoReps()">
          <AuraSection />
        </div>
        <div class="--global-clickable" @click="gotoProfile()">
          <BeltSection />
        </div>
      </FullWidthHorizontalScroll>
    </div>

    <div v-if="isMobile" class="mobileView">
      <Carousel 
        class="carousel"
        :navigate-to="pageNumber"
        :per-page="1" 
        :pagination-enabled="true"
        :navigation-enabled="false"
        :pagination-active-color="paginationActiveColor"
        :pagination-color="paginationColor">
        <slide>
          <div class="--global-clickable" @click="gotoReps()">
            <AuraSection />
          </div>
        </slide>
        <slide>
          <div class="--global-clickable" @click="gotoProfile()">
            <BeltSection />
          </div>
        </slide>
      </Carousel>
    </div>

  </div>
</template>
  
  <script>
  import BeltSection from './BeltSection';
  import AuraSection from './AuraSection';
  import FullWidthHorizontalScroll from '../../components/FullWidthHorizontalScroll';
  import { mapGetters } from 'vuex';
  import { Carousel, Slide } from 'vue-carousel';
  
  export default {
    components: {  
        BeltSection,
        AuraSection,
        FullWidthHorizontalScroll,
        Carousel,
        Slide},
    data: function() {
      return {
        paginationActiveColor: 'white',
        paginationColor: 'rgb(55 61 101)',
        pageNumber: 0,
      }
    },

    computed: {
        ...mapGetters({
            repsLeft: 'repetition/repsLeft',
            repetitionsLoaded: 'repetition/loaded',
        }),
        isMobile() {
            const mobileBreakpoint = 767;
            const screenWidth = window.screen.width;

            if (screenWidth <= mobileBreakpoint) {
                return true;
            } else {
                return false;
            }
        },
    },

    watch: {
      repetitionsLoaded: {
            handler: function (val) {
                if(val){
                  if(!this.repsLeft){
                    this.pageNumber = 1;
                  }
                }
            },
            immediate: true
        }
    },
  
    methods: {
      gotoReps() {
        this.$router.push({ name: 'Repetitions' });
      },

      gotoProfile() {
        this.$router.push({ name: 'DashboardProfileView' });
      },
    },
  
  }
  </script>
  
  <style lang="scss" scoped>
  .desktopView{
    display: flex;
    justify-content: center;
  }
  .mobileView{
    z-index: 1000;
  }
  .item-wrapper {
    flex-basis: initial;
  }
  .aura {
      display: flex;
      justify-content: space-between;
      .BeltSection {
          flex: 1;
          margin-left: 1em;
      }
    }

    .section {
        position: relative;
        padding-left: calc(var(--widthWrapperSideWidth) * 1px);
        padding-right: calc(var(--widthWrapperSideWidth) * 1px);
        padding-top: 2.5em;
        padding-bottom: 2em;

        &:empty {
            border: 1px solid red;
            display: none;
        }

        &.disabled::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        @media only screen and (min-width: 600px) and (max-width: 1200px) {
            padding-left: calc(var(--widthWrapperSideWidth) * 0.3px);
            padding-right: calc(var(--widthWrapperSideWidth) * 0.3px);
        }
    }

    .carousel {
      /deep/ .VueCarousel-pagination {
        .VueCarousel-dot-container{
          margin-top: 0 !important;
          @media only screen and (max-width: 600px) {
            margin-top: -3vw !important;
          }
        }
      }
    }
  </style>
  
  
