<template>
  <div class="MissionTotalProgressCircular">
    <CircularProgress
      class="soloProgressCircular"
      :progress="progress"
      color="rgba(255, 255, 255, 0.9)"
      track-color="rgba(0, 0, 0, 0.15)"
      :stroke-width="3.5"
    />
    <!-- <CircularProgress
      v-if="selectedTeamId !== '0'"
      class="TeamProgressCircular"
      :progress="teamProgress"
      color="rgba(0, 183, 255, 0.9)"
      track-color="rgba(0, 0, 0, 0.15)"
      :stroke-width="3.5"
    /> -->
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'

export default {
  components: { CircularProgress },
  props: {
    progress: {
      type: Number,
      required: true,
    },
    teamProgress: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
  },
}
</script>

<style lang="scss" scoped>
.MissionTotalProgressCircular {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 2.8em;
  z-index: 2;
  width: 8.7em;
  height: 8.7em;

  @media only screen and (max-width: 600px) {
    top: 8vw;
    width: 20.4vw;
    height: 20.4vw;
  }

  .soloProgressCircular {
    position: absolute;
    width: 7.7em;
    height: 7.7em;

    @media only screen and (max-width: 600px) {
      width: 18.4vw;
      height: 18.4vw;
    }
  }
  .teamProgressCircular {
    position: absolute;
  }
}
</style>
