<template>
  <div class="UniverseLifeskillCircularProgress">
    <CircularProgress
      v-if="lifekeyProgress"
      :progress="lifekeyProgress"
      track-color="rgba(255, 255, 255, 0.1)"
      color="rgba(255, 255, 255, 0.6)"
      :stroke-width="3"
    />
  </div>
</template>

<script>
import CircularProgress from '@/components/CircularProgress'

export default {
  components: { CircularProgress },
  props: {
    lifeskillId: {
      type: String,
      required: true,
    },
  },
  computed: {
    stats() {
      return this.$store.getters['moduleApp/getLifeskillStats'](this.lifeskillId)
    },
    lifekeyProgress() {
      return this.stats.progress
    },
  },
}
</script>

<style lang="scss" scoped>
.UniverseLifeskillCircularProgress {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
