<template>
  <div
    class="ItemBase"
    :class="{ pulse, completed: progress === 1, locked, '--global-clickable': progress < 1 && !locked }"
    @click="handleClick()">
    <div
      v-if="label"
      class="label">
      {{ label }}
    </div>
    <div
      class="icon"
      :style="{ backgroundImage: `url(${icon})` }" />
    <div class="spacer" />

    <div
      class="title"
      v-html="title" />

    <div
      v-if="progress === 1"
      class="status">
      <svgicon icon="checkmark" />
    </div>
    <div
      v-else-if="locked"
      class="status">
      <svgicon icon="lock2" />
    </div>
  </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            default: ''
        },
        pulse: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0
        }
    },
    computed: {
        locked() {
            return !this.pulse && this.progress < 1;
        }
    },
    methods: {
        handleClick() {
            if (this.locked) {
                return;
            }
            this.$emit('click');
        }
    }
};
</script>

<style lang="scss" scoped>
$topPadding: 1em;
$sidePadding: 1em;
$bottomPadding: 1.5em;

.ItemBase {
    position: relative;
    height: (238em / 15); // 9em;
    width: (168em / 15); // 17em;
    padding: $topPadding $sidePadding;
    padding-bottom: $bottomPadding;
    background-color: rgba(black, 0.2);
    border-radius: $dashboard-itemBorderRadius;
    overflow: hidden;
    transform: translate3d(0, 0, 0); // Avoid Safari visual bug, where overflow: hidden is ignored while scaling .image

    opacity: 0.3;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &:not(.locked):not(.completed):hover {
        .icon {
            transform: scale(1.1);
        }
    }
    &.completed {
        opacity: 1;
    }
    &.locked {
    }
    &.pulse {
        opacity: 1;
    }

    $pulseDuration: 1.5s;
    &.pulse {
        animation: pulse-animation2 $pulseDuration infinite cubic-bezier(0.66, 0, 0, 1);

        box-shadow: 0 0 0 0 #3f6bb4; // $standardButtonColor;

        @keyframes pulse-animation2 {
            to {
                box-shadow: 0 0 0 0.5em rgba($standardButtonColor, 0);
            }
        }
    }
}
.icon {
    $size: 5em;
    align-self: center;
    margin-top: 2em;
    width: $size;
    height: $size;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    transition: transform 0.3s;
}
.label {
    color: rgba(white, 0.5);
    position: relative;
}
.spacer {
    flex: 1;
}
.title {
    font-weight: 600;
    font-size: 120%;
    color: rgba(white, 0.9);
    position: relative;
}
.status {
    $size: 1.5em;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 1em;
    right: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    .svg-icon {
        $size: 0.8em;
        width: $size;
        height: $size;
        fill: $dashboard-darkSectionBackground;
    }
}
</style>
