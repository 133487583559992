<template>
  <div class="UniverseSection">
    <div class="universe">
      <h1 class="headerUniverse"> {{ $t('universeTitle') }} </h1>

      <div class="categoryTitlesContainer">
        <div
          v-for="category in categories"
          :key="category"
          class="categoryTitle"
          :class="{ selected: categorySelected === category }"
          @click="selectCategory(category)"
        >
          {{ $t(category) }}
        </div>
      </div>

      <div id="riveContainer" class="riveContainer">
        <div id="lifeskillTextContainer" class="lifeskillTextContainer" :class="{ show: showLifeskillText }">
          <div v-show="lifeskillSelected === '00'" class="lifeskillText"
            >Er det ikke ironisk at vi går gjennom livet uten en god «brukermanual» for hjernen? I solsystem 00 -
            Hjernerevolusjonen - får du både innsikten for en bedre hjerne og en bedre hverdag.</div
          >
          <div v-show="lifeskillSelected === '01'" class="lifeskillText"
            >I dagens samfunn blir hjernen bombardert med såpass mye informasjon at den for mange har blitt programmert
            til å forstyrre seg selv når vi har fri. I solsystem 01 finner du tilbake til lynfokus og
            «hvor-ble-det-av-tiden»-konsentrasjon.</div
          >
          <div v-show="lifeskillSelected === '02'" class="lifeskillText"
            >Hva skal vi med bedre hukommelse når vi har Google? Vel, dumme spørsmål gir både dumme svar og dumme
            tanker. Med teknikkene i solsystem 02 blir du ikke bare et huskegeni, men løfter også hjernen din til å bli
            smartere ellers i livet.</div
          >
          <div v-show="lifeskillSelected === '03'" class="lifeskillText"
            >Hva skal vi med hoderegning når vi har mobilen i lomma? Med teknikkene i solsystem 03 vil du ikke bare bli
            et tallgeni og kunne regne raskere i hodet enn du rekker å ta frem mobilen, men fremfor alt ligge et hode
            foran i alt som har med tall å gjøre.</div
          >
          <div v-show="lifeskillSelected === '10'" class="lifeskillText"
            >Hvorfor gå når vi kan løpe eller fly? Behovet for hurtig endring har aldri vært større enn i dagens
            samfunn. I solsystem 10 vil du mestre meta-ferdigheten av å bruke hjernen til å lære kunnskap og ferdigheter
            i lynets hastighet.</div
          >
          <div v-show="lifeskillSelected === '11'" class="lifeskillText"
            >Ok, du har kanskje hatt årevis med fransk, tysk eller fransk på skolen, men føler allikevel at det meste
            har gått i glemmeboken? I solystem 11 skal det heldigvis ikke mer enn 3-6 måneders morsom lynlæring til for
            å bli funksjonelt flytende i et språk.</div
          >
          <div v-show="lifeskillSelected === '14'" class="lifeskillText"
            >Hvor var det sa du? I solsystem 14 blir du en mester i geografi og vil ikke bare kunne lire av deg alle
            verdens land og hovedsteder, men fremfor alt fylle hjernen din med den kulturelle og geopolitiske kapitalen
            som skiller klinten fra hveten.</div
          >
          <div v-show="lifeskillSelected === '18'" class="lifeskillText"
            >At historien gjentar seg er ingen myte. I solystem 18 reiser du gjennom 1000 hendelser fra
            verdenshistorien, samtidig som du både spiller hjernen din topptrent med huskesystemer og opparbeider deg
            allsidig samfunnsforståelse.</div
          >
          <div v-show="lifeskillSelected === '25'" class="lifeskillText"
            >Ja, du har lært både alfabetet og gangetabellen, men hva med periodetabellen? I solystem 25 vil du lynraskt
            lære deg jordens (og universets) byggestener, slik at din kjemiforståelse løfter hverdagen innen alt fra
            matlaging og helse til økonomi og investering.</div
          >
          <div v-show="lifeskillSelected === '37'" class="lifeskillText"
            >De fleste av oss innser alt for sent i livet at lykke er et valg vi gjør hver dag. I solsystem 37 vil du
            oppdage de «smittsomme» motivasjonsknappene i hjernen.</div
          >
          <div v-show="lifeskillSelected === '41'" class="lifeskillText"
            >I dette solsystemet finner du et topp 10 av hjernehacks som vil spille hjernen din veltrent, så du virkelig
            kan slippe løs din hverdagshelt. Resultatet er bedre selvledelse, så du lettere hopper tilbake i førersetet
            av din mentale bil og oppnår toppfart nærmest når du vil.</div
          >
          <div v-show="lifeskillSelected === '40'" class="lifeskillText"
            >'For de fleste fylles nyttårsaften med optimistiske mål, men allerede halvveis i januar er de gamle vanene
            tilbake. I solsystem 40 blir du kjent med teknikkene som hjelper deg nå både nyttårsforsett og livsmål.</div
          >
          <div v-show="lifeskillSelected === '42'" class="lifeskillText"
            >Hva er det som gjør at kommunikasjonen flyter så lett med noen, men kræsjer med andre? I solsystem 42 vil
            du mestre kunsten å snakke til «hele hjernen» og tilpasse kommunikasjonen slik at budskapet lander nesten
            uansett.</div
          >
          <div v-show="lifeskillSelected === '44'" class="lifeskillText"
            >«Gammel vane er vond å vende» sies det … men i solsystem 44 vil du mestre kunsten å endre en vane lynraskt
            - særlig sammen med teamet ditt, vennene dine eller familien.</div
          >
          <div v-show="lifeskillSelected === '45'" class="lifeskillText"
            >Synes du det er en god idé å oppføre seg mot andre som du vil at andre oppfører seg mot deg? Hjernen vår er
            såpass unik at «one size fits all» ikke fungerer. Med kunnskap om hjernens tanke- og sansetyper blir det vel
            og merke enkelt å tilpasse seg, slik at budskapene dine lander og samarbeidet flyter.</div
          >
          <div v-show="lifeskillSelected === '48'" class="lifeskillText"
            >'Hvor flink er du til å ta kritikk eller til å bli inspirert av en «konkurrent»? I solsystem 48 vil du
            mestre veksttankesettet som magnetiserer hjernen din (og andres) til løpende forbedringer som løfter
            hverdagen fra «good to great».</div
          >
          <div v-show="lifeskillSelected === '50'" class="lifeskillText"
            >Hvorfor er det slik at noen helt naturlig blir midtpunktet i enhver sosiale setting? I solsystem 50 vil du
            imponere deg selv med alt fra å lynraskt huske folks navn til kunsten å bygge de riktig gode relasjonene
            over tid.</div
          >
          <div v-show="lifeskillSelected === '54'" class="lifeskillText"
            >Noen morgener eller ettermiddager kan det føles som at batteriet er flatt. I solsystem 54 vil du mestre
            teknikker som gjør det lett å «lade opp» kroppen med ny energi, slik at du får mer ut av hverdagen.</div
          >
          <div v-show="lifeskillSelected === '55'" class="lifeskillText"
            >Noen ganger er kun et blikk eller en kroppsholdning alt som skal til for at misforståelsen er faktum. I
            solsystem 55 vil du mestre kroppsspråket og ansiktsmimikken som gir økt menneskeinnsikt, lar deg ta bedre
            beslutninger og styrker dine relasjoner.</div
          >
          <div v-show="lifeskillSelected === '63'" class="lifeskillText"
            >Vi liker jo å tenke at vi selv bestemmer hvordan vi har det, men her er kroppen ikke alltid enig med
            hjernen. I solsystem 63 vil du mestre hvordan du lynraskt kan endre hvordan du har det i hodet ved å endre
            hvordan du har det i kroppen.</div
          >
          <div v-show="lifeskillSelected === '70'" class="lifeskillText"
            >Noen dager, og i noen livsperioder, er vi såpass stresset at det går ut over både vår mentale og fysiske
            helse. I solsystem 70 vil du lære å få bedre mentalhygiene og stresse ned kroppen.</div
          >
          <div v-show="lifeskillSelected === '74'" class="lifeskillText"
            >«Oi, som tiden har gått» - det er tilstanden vi befinner oss i når vi er i flow. I solsystem 74 vil du lære
            hvordan du både kan mangegange produktiviteten og kreativiteten din med denne magiske hjernetilstanden.</div
          >
          <div v-show="lifeskillSelected === '77'" class="lifeskillText"
            >En god natts søvn er lett å ta for gitt når vi har det og noe vi kan «dø» av når vi ikke får det. I
            solsystem 77 vil du lære de forskningsbaserte teknikkene som trener hjernen din til deilig zzzzzzzz.</div
          >
          <div v-show="lifeskillSelected === '80'" class="lifeskillText"
            >Vi er blitt eksperter i å ha det travelt, men hvor mye av de viktige tingene får vi egentlig gjort? I
            solsystem 80 vil du kunne spille hjernen til dobbel produktivitet med halv innsats.</div
          >
          <div v-show="lifeskillSelected === '83'" class="lifeskillText"
            >Enten det er snakk om i et møte på jobben eller med venner og familie - forhandlinger setter preg på
            hverdagen. I solsystem 83 vil du spille hjernen til topp veksttankesett og utfordrersalgsmentaliteten som
            gjør at du sitter igjen med det lengste strået.</div
          >
          <div v-show="lifeskillSelected === '87'" class="lifeskillText"
            >Vi har alle beslutninger, som det er lett å angre på. I solsystem 87 vil du mestre hvordan du kan spille
            hjernen din til både å ta bedre beslutninger i hverdagen og lande på de gode beslutningene i livet.</div
          >
          <div v-show="lifeskillSelected === '88'" class="lifeskillText"
            >Uavhengig av om du tenker at du er kreativ eller ikke, så kan hjernen din trenes til det. I solsystem 88
            vil hjernen din blomstre med kreativ problemløsning i hverdagen og teknikker for å innovere fremtiden.</div
          >
          <div v-show="lifeskillSelected === '93'" class="lifeskillText"
            >"Å tisse i buksa for å holde seg varm" har lenge vært tankesettet som nasjoner, bedrifter og privatpersoner
            har hatt i forhold til eget forbruk. I solsystem 93 vil du mestre 'bærekraft lynraskt' - morsom lynlæring av
            FNs 17 bærekraftsmål.</div
          >

          <div class="lifeskillButton" @click="gotoCourse(lifeskillSelected)"> Start </div>
        </div>

        <canvas
          v-show="!showPopularTabImage"
          id="universeCanvas"
          class="canvas"
          :class="{ categorySelected: lifeskillSelected === null }"
          width="800"
          height="500"
        />

        <LifeskillCircularProgress
          v-if="lifeskillSelected != null"
          slot="center"
          :lifeskill-id="lifeskillSelected"
          :class="{ show: showLifeskillText }"
          class="lifeskillCircularProgressBig"
        />

        <img
          v-show="showPopularTabImage"
          id="popularTabImage"
          class="popularTabImage"
          src="./assets/images/popular-tab-illustration.png"
          alt=""
          width="1600"
          height="628"
        />
        <img
          v-show="showPopularTabImage"
          id="popularTabImageMobile"
          class="popularTabImageMobile"
          src="./assets/images/popular-tab-mobile.png"
          alt=""
          width="819"
          height="547"
        />

        <div
          v-show="categorySelected === 'brain' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra den gale gullfisken!
        </div>
        <div
          v-show="categorySelected === 'knowledge' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra apestreksmonsteret!
        </div>
        <div
          v-show="categorySelected === 'mind' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra det sleipe pinnsvinet!
        </div>
        <div
          v-show="categorySelected === 'body' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra det late dovendyret!
        </div>
        <div
          v-show="categorySelected === 'soul' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra den bitre flamingoen!
        </div>
        <div
          v-show="categorySelected === 'career' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra den onde roboten!
        </div>
        <div
          v-show="categorySelected === 'leisure' && lifeskillSelected === null"
          id="categorySubtitle"
          class="categorySubtitle"
        >
          Frigjør galaksen fra den mutante fluen!
        </div>
      </div>

      <div v-if="categorySelected === 'interests'" class="lifeskillsContainer">
        <div class="interestsTitleContainer">
          <div class="lifeskillsTitleContainer">
            <div class="lifeskillsTitle">{{ $t('starContent') }}</div>
            <div class="lifeskillIconsContainer">
              <img class="lifeskillsIcon" src="./assets/icons/theory-icon-universe.svg" alt="" />
              <img class="lifeskillsIcon" src="./assets/icons/practice-icon-universe.svg" alt="" />
              <img class="lifeskillsIcon" src="./assets/icons/videos-icon-universe.svg" alt="" />
            </div>
          </div>

          <div class="lifeskillsTitleContainer" @click="editInterests()">
            <div class="changeText"> {{ $t('change') }} </div>
            <img class="editIcon" src="./assets/icons/edit-icon.svg" alt="" />
          </div>
        </div>

        <carousel
          :navigate-to="[0, false]"
          :per-page="1"
          :pagination-enabled="true"
          :navigation-enabled="false"
          :navigation-click-target-size="20"
          :pagination-active-color="'white'"
          :pagination-color="'rgb(55 61 101)'"
          class="carousel"
        >
          <slide v-for="(items, index) in interestsItems" :key="index" class="item-wrapper">
            <div class="lifeskillsGrid">
              <div
                v-for="ls in items"
                :key="ls"
                :class="{ selected: lifeskillSelected === ls }"
                class="lifeskillContainer"
                @click="isDwarfStar(ls) ? gotoCourse(ls) : selectLifeskill(ls)"
              >
                <LifeskillSunImage :lifeskill-id="ls" :dwarf-star="isDwarfStar(ls)" />
                <p class="lifeskillNum" :class="{ dwarfStar: isDwarfStar(ls) }">{{ ls }}</p>
                <LifeskillCircularProgress slot="center" :lifeskill-id="ls" class="lifeskillCircularProgress" />
                <div class="lifeskillName">{{ $t(`dashboard.general.lifeskillLabels.${ls}`) }}</div>
              </div>
              <div v-for="i in 8" :key="i">
                <div v-if="items.length < i" class="lifeskillContainer opacitate" />
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <div v-if="categorySelected != 'interests'" class="lifeskillsContainer">
        <div class="lifeskillsTitleContainer">
          <div class="lifeskillsTitle">{{ $t('starContent') }}</div>
          <div class="lifeskillIconsContainer">
            <img class="lifeskillsIcon" src="./assets/icons/theory-icon-universe.svg" alt="" />
            <img class="lifeskillsIcon" src="./assets/icons/practice-icon-universe.svg" alt="" />
            <img class="lifeskillsIcon" src="./assets/icons/videos-icon-universe.svg" alt="" />
          </div>
        </div>

        <carousel
          :navigate-to="[0, false]"
          :per-page="1"
          :pagination-enabled="true"
          :navigation-enabled="false"
          :navigation-click-target-size="20"
          :pagination-active-color="'white'"
          :pagination-color="'rgb(55 61 101)'"
          class="carousel"
        >
          <slide v-for="(lchunk, index) in chunkedLifeskills" :key="index" class="item-wrapper">
            <div class="lifeskillsGrid">
              <div
                v-for="ls in lchunk"
                :key="ls"
                :class="{ selected: lifeskillSelected === ls }"
                class="lifeskillContainer"
                @click="isDwarfStar(ls) ? gotoCourse(ls) : selectLifeskill(ls)"
              >
                <LifeskillSunImage :lifeskill-id="ls" :dwarf-star="isDwarfStar(ls)" />
                <p class="lifeskillNum" :class="{ dwarfStar: isDwarfStar(ls) }">{{ ls }}</p>
                <LifeskillCircularProgress slot="center" :lifeskill-id="ls" class="lifeskillCircularProgress" />
                <div class="lifeskillName">{{ $t(`dashboard.general.lifeskillLabels.${ls}`) }}</div>
              </div>
              <div v-for="i in 8" :key="i">
                <div v-if="lchunk.length < i" class="lifeskillContainer opacitate" />
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<translations>
  

  universeTitle: 'Level Up Your Brain'
  universeTitle_no: 'Spill hjernen veltrent'
  lifeStars: 'Life Stars'
  lifeStars_no: 'Livsstjerner'
  dwarfStars: 'Dwarf Stars'
  dwarfStars_no: 'Dvergstjerner'

  starContent: 'Star content'
  starContent_no: 'Stjerneinnhold'

  interests: 'Your interests'
  interests_no: 'Dine interesser'
  brain: 'Brain'
  brain_no: 'Hjerne'
  knowledge: 'Knowledge'
  knowledge_no: 'Kunnskap'
  mind: 'Mind'
  mind_no: 'Sinn'
  body: 'Body'
  body_no: 'Kropp'
  soul: 'Soul'
  soul_no: 'Sjel'
  career: 'Career'
  career_no: 'Karriere'
  leisure: 'Leisure'
  leisure_no: 'Fritid'

  change: 'Change'
  change_no: 'Endre'
</translations>

<script>
const rive = require('rive-js')
import RiveFile from './assets/rive/galaxy-solar-system.riv'
import LifeskillCircularProgress from './UniverseLifeskillCircularProgress'
import { mapGetters } from 'vuex'
import LifeskillSunImage from './LifeskillSunImage.vue'
import { Carousel, Slide } from 'vue-carousel'

export default {
  components: { LifeskillCircularProgress, LifeskillSunImage, Carousel, Slide },
  data() {
    return {
      r: null,
      inputs: null,
      categories: ['interests', 'brain', 'knowledge', 'mind', 'body', 'soul', 'career', 'leisure'],
      categorySelected: 'interests',
      lifeskillSelected: null,
      showLifeskillText: false,
      showLifeskillTextTimer: null,
    }
  },

  mounted() {
    this.setRive()
  },
  computed: {
    ...mapGetters({
      getAcquiredVideoBraindates: 'moduleApp/getVideoBraindatesMap',
      getLifeskillStats: 'moduleApp/getLifeskillStats',
      profile: 'moduleAuth/profile',
    }),

    isMobile() {
      if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    interestsItems() {
      const interestsItems = this.$store.getters['moduleApp/recommendedItems']
      let chunkSize = this.isMobile ? 6 : 8
      return this.chunk(interestsItems, chunkSize) || []
    },

    showPopularTabImage() {
      if (this.categorySelected === 'interests' && this.lifeskillSelected === null) {
        return true
      } else {
        return false
      }
    },

    lifeskills() {
      if (this.categorySelected === 'interests') {
        return []
      } else {
        return this.$store.getters['moduleApp/getCategoryById'](this.categorySelected).lifeskills
      }
    },

    lifeStars() {
      return this.lifeskills.filter(lifeskill => {
        let stats = this.getLifeskillStats(lifeskill)
        return stats.totalTheoryLifekeys > 0
      })
    },

    dwarfStars() {
      return this.lifeskills.filter(lifeskill => {
        let stats = this.getLifeskillStats(lifeskill)
        let numberOfVideos = this.$store.state.moduleApp.lifeskills[lifeskill].numberOfRelatedVideos
        return stats.totalTheoryLifekeys === 0 && numberOfVideos > 0
      })
    },

    chunkedLifeskills() {
      const allLifeskills = this.lifeStars.concat(this.dwarfStars)
      let chunkSize = this.isMobile ? 6 : 8
      return this.chunk(allLifeskills, chunkSize)
    },
  },

  beforeDestroy() {
    this.r.stop()
    this.r.unsubscribeAll()
    this.r = null
  },

  methods: {
    gotoCourse(ls) {
      this.$router.push({
        name: 'DashboardLifeskillView',
        params: {
          lifeskillId: ls,
        },
      })
    },
    editInterests() {
      this.$router.push({
        name: 'FollowLifeskillView',
      })
    },
    setRive() {
      this.r = new rive.Rive({
        src: new Request(RiveFile),
        canvas: document.getElementById('universeCanvas'),
        stateMachines: 'universeStates',
        autoplay: true,
        layout: new rive.Layout({ fit: 'cover', alignment: 'center' }),
        onload: () => {
          this.inputs = this.r.stateMachineInputs('universeStates')
          this.isMobileAnimation(this.isMobile)
        },
      })
    },
    setLifeskillNum(ls) {
      const lifeskillNum = parseInt(ls)
      this.inputs[0].value = lifeskillNum
    },
    setLifeskillSunColor(ls) {
      const lifeskillNum = parseInt(ls)
      this.inputs[1].value = lifeskillNum
    },
    setGalaxyNum(num) {
      this.inputs[2].value = num
    },
    playMainAnimation(bool) {
      this.inputs[3].value = bool
    },
    isMobileAnimation(bool) {
      this.inputs[4].value = bool
    },
    isOpacitatedMobile(bool) {
      this.inputs[5].value = bool
    },

    selectCategory(categoryName) {
      this.showLifeskillText = false
      this.categorySelected = categoryName
      this.lifeskillSelected = null
      this.setLifeskillNum(0)
      this.setGalaxyNum(this.categories.indexOf(categoryName))
      this.playMainAnimation(false)
    },

    selectLifeskill(ls) {
      if (this.lifeskillSelected === null) {
        clearTimeout(this.showLifeskillTextTimer)
        this.showLifeskillText = false
        this.setGalaxyNum(0)
        this.lifeskillSelected = ls
        this.setLifeskillSunColor(ls)
        this.setLifeskillNum(ls)
        this.playMainAnimation(true)
        this.showLifeskillTextTimer = setTimeout(() => {
          this.showLifeskillText = true
          if (this.isMobile) {
            this.isOpacitatedMobile(true)
          }
        }, 200)
      } else if (this.showLifeskillText) {
        this.showLifeskillText = false
        this.showLifeskillTextTimer = setTimeout(() => {
          this.showLifeskillText = true
        }, 50)
        this.lifeskillSelected = ls
        this.setLifeskillSunColor(ls)
        this.setLifeskillNum(ls)
      }
    },

    isDwarfStar(lifeskill) {
      let stats = this.getLifeskillStats(lifeskill)
      let numberOfVideos = this.$store.state.moduleApp.lifeskills[lifeskill].numberOfRelatedVideos
      return stats.totalTheoryLifekeys === 0 && numberOfVideos > 0
    },

    chunk(arr, chunkSize) {
      if (chunkSize <= 0) throw 'Invalid chunk size'
      var R = []
      for (var i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize))
      return R
    },
  },
}
</script>

<style lang="scss" scoped>
$h1-title: 3.8em;
$h1-title-vw: 8vw;
$h2-title: 2.5em;
$h2-title-vw: 7.5vw;
$h2B-title: 2.3em;
$h2-title-vw: 7vw;
$h3-title: 2em;
$h3-title-vw: 6vw;
$h4-title: 1.5em;
$h4-title-vw: 5vw;
$h5-title: 1.3em;
$h4-title-vw: 4.7vw;
$sub-text: 1.25em;
$sub-text-vw: 4.3vw;
$paragraph: 1.1em;
$paragraph-vw: 3.4vw;
$smalltext: 0.95em;
$smalltext-vw: 2.6vw;
$uppertitle: 0.8em;
$uppertitle-vw: 2.7vw;
$title-font: 'Raleway', sans-serif;
$text-font: Source Sans Pro;
$camelcase-spacing: 0.02em;
$uppercase-spacing: 0.1em;
$color-white: #d6d4ef;
$color-gray: #a09dac;
$color-lightblue: #9eaeee;
$line-height: 1.55em;

//spacers for layout//
$spacing-4px: 4px;
$spacing-8px: 8px;
$spacing-12px: 12px;
$spacing-16px: 16px;
$spacing-20px: 20px;
$spacing-24px: 24px;

.universe {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 4em;
  z-index: 1;

  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    margin-top: 0;
    width: 100vw;
    padding-top: 6vw;
  }
}
.headerUniverse {
  color: $color-white;
  font-size: 2.5em;
  font-family: $title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  margin: 0;
  margin-bottom: 0;
  width: 20em;

  @media only screen and (max-width: 600px) {
    font-size: 7vw;
    margin: 0;
    margin-bottom: 1vw;
    width: 95vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 5vw;
    width: 100vw;
  }
}
.lifeskillTextContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 20em;
  margin-bottom: 2em;
  opacity: 0;
  transition: opacity 0s ease-in;
  &.show {
    opacity: 1;
    transition: opacity 1s ease-in;
  }

  @media only screen and (max-width: 600px) {
    width: 80vw;
    margin-bottom: 8vw;
    align-items: center;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 70vw;
    margin-bottom: 4vw;
  }
}
.lifeskillText {
  text-align: left;
  color: $color-gray;
  font-family: $text-font;
  font-size: $paragraph;
  letter-spacing: $camelcase-spacing;

  @media only screen and (max-width: 600px) {
    font-size: 4vw;
    padding-left: 4vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 2.6vw;
    width: 50vw;
    margin-left: 10vw;
  }
}
.lifeskillButton {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  background: #4452fe;
  text-align: center;
  font-size: 1em;
  font-weight: 500;
  border-radius: 0.3em;
  padding: 0.5em 2em;
  margin-top: 1.3em;
  z-index: 1;

  &:hover {
    cursor: pointer;
    background: #6471ff;
  }

  @media only screen and (max-width: 600px) {
    font-size: 3.4vw;
    font-weight: 500;
    border-radius: 0.7vw;
    padding: 1.8vw 6vw;
    margin-top: 6vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 2.2vw;
    font-weight: 500;
    border-radius: 0.5vw;
    padding: 1vw 4vw;
    margin-top: 4vw;
    margin-left: 10vw;
  }
}
.riveContainer {
  position: relative;
  height: 19em;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media only screen and (max-width: 600px) {
    height: 60vw;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    height: 45vw;
    padding-top: 2vw;
  }
}
.canvas {
  height: 430px;
  margin-bottom: 0em;
  &.categorySelected {
    height: 350px;
    margin-bottom: 3em;
  }

  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    padding-bottom: 15vw;
    margin-right: 0;
    &.categorySelected {
      height: auto;
      margin-bottom: 0em;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    padding-bottom: 0;
    &.categorySelected {
      width: 90vw;
      height: auto;
      margin-bottom: 5vw;
    }
  }
}
.popularTabImage {
  display: flex;
  width: 55em;
  height: auto;
  margin-bottom: 0;

  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 100vw;
  }
}
.popularTabImageMobile {
  display: none;

  @media only screen and (max-width: 600px) {
    display: flex;
    width: 92vw;
    height: auto;
    margin-bottom: 8vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
  }
}
.categoryTitlesContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  font-family: 'HelveticaNeue', sans-serif;

  @media only screen and (max-width: 600px) {
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;
    justify-content: flex-start;
    width: 100vw;
    height: 20vw;
    padding-bottom: 3vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 100vw;
    height: 12vw;
    padding-left: 3vw;
    padding-right: 3vw;
  }
}
.categoryTitle {
  color: $color-gray;
  font-family: $text-font;
  font-size: $sub-text;
  letter-spacing: $camelcase-spacing;
  font-weight: 500;
  line-height: 1.3em;
  margin: 1em;
  opacity: 0.4;
  cursor: pointer;
  transition: all 0.2s ease-in;
  border-radius: 6em;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 1;
  white-space: nowrap;

  &.selected {
    background: #1d1772;
    color: white;
    opacity: 1;
    padding: 0.5em 1em;
  }

  @media only screen and (max-width: 600px) {
    width: 90vw;
    font-size: 3.7vw;
    margin: 3.2vw;
    padding: 0;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 2.2vw;
    line-height: 3vw;
    margin: 2vw;
  }
}
.categoryTitlesContainer::-webkit-scrollbar {
  @media only screen and (max-width: 600px) {
    display: none;
  }
}
.categoryTitle {
  @media only screen and (max-width: 600px) {
    padding: 1.5vw;
  }
}
.categoryTitle:hover {
  background: #646efc11;
  color: white;
  opacity: 0.7;
  padding: 0.5em 1em;

  @media only screen and (max-width: 600px) {
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    transform: translateY(-4px);
    background: #646efc11;
    color: white;
    opacity: 0.7;
    padding: 0.5em 1em;
    letter-spacing: 0.4px;
  }
}

.galaxyImageContainer {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 600px) {
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }
}
.galaxyImage {
  padding: 1em;
  @media only screen and (max-width: 600px) {
    padding: 4vw;
    margin: 0vw;
    height: 60vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 1em;
  }
}
.imageLifeskillNumber {
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  font-size: 2.3em;
  font-family: 'HelveticaNeue', sans-serif;
  opacity: 0;

  @media only screen and (max-width: 600px) {
    padding-top: 0.7vw;
    padding-left: 3vw;
    padding-right: 2vw;
    margin-right: 4vw;
    font-size: 3.2vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
    font-size: 2.3em;
    font-family: 'HelveticaNeue', sans-serif;
    opacity: 0;
  }
}
.categorySubtitle {
  position: absolute;
  align-self: flex-end;
  font-family: 'Source Sans Pro';
  text-align: center;
  font-size: 1.2em;
  width: 100%;
  color: #9a9dff;
  opacity: 1;
  padding-bottom: 1.5em;
  &.hide {
    opacity: 0;
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.2vw;
    padding-bottom: 5vw;
    width: 85vw;
    padding-left: 0;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    // width: 95vw;
    padding-bottom: 3vw;
    padding-left: 1.5vw;
    font-size: 2.6vw;
  }
}

.lifeskillsTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin-left: calc(50vw - 31em)
  padding: 1em 0 0.7em 0.5em;

  @media only screen and (max-width: 600px) {
    padding: 2.5vw 3vw 1.8vw 1.5vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 2vw 0 0.7vw 0;
  }
}

.interestsTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60.8em;

  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 97vw;
  }
}
.lifeskillsTitle,
.changeText {
  font-family: 'Source Sans Pro';
  text-align: center;
  font-size: 1.3em;
  font-weight: 500;
  color: #ffffffdc;
  opacity: 1;
  margin-right: 0.6em;

  @media only screen and (max-width: 600px) {
    margin-right: 2vw;
    margin-left: 1vw;
    font-size: 4.4vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    margin-right: 1vw;
    margin-left: 2.5vw;
    font-size: 2.6vw;
  }
}
.changeText {
  color: #bde3ffdc;
  font-size: 1.2em;
  @media only screen and (max-width: 600px) {
    margin-right: 1vw;
    font-size: 4vw;
  }
}
.lifeskillIconsContainer {
  display: flex;
  align-items: center;
}
.lifeskillsIcon {
  width: 2em;
  height: 2em;
  margin-left: 0.6em;

  @media only screen and (max-width: 600px) {
    width: 6vw;
    height: 6vw;
    margin-left: 2vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 3vw;
    height: 3vw;
    margin-left: 2vw;
  }
}

.lifeskillsContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 63.5em;

  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    justify-content: center;
    width: 100vw;
  }
}

.lifeskillsHorizontalScroll {
  width: 100vw;
  padding-top: 0;

  /deep/ .sideFiller {
    width: calc(50vw - 31em);
    @media only screen and (min-width: 600px) and (max-width: 1200px) {
      width: 0;
    }
  }
}

.popularLifeskillsGrid {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-items: center;
  width: 70em;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    justify-content: flex-start;
    width: 95vw;
  }
}
.lifeskillsGrid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  width: 61.5em;

  @media only screen and (max-width: 600px) {
    width: 100vw;
    flex-flow: row wrap;
    flex-direction: column;
    height: 54vw;
    // margin-left: 4vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 0;
    flex-flow: row wrap;
    flex-direction: column;
    height: 23vw;
    margin-left: 1.4vw;

    &.moreThanEight {
      height: 23vw;
    }

    &.fourOrLess {
      flex-direction: row;
      height: auto;
    }
    &.twoOrLess {
      height: auto;
    }
  }
}

.lifeskillContainer {
  position: relative;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  align-items: center;
  padding: 0.3em 1em;
  background-color: #201b5cb3;
  height: 4em;
  width: 14.37em;
  font-size: 1em;
  color: white;
  margin: 0.5em;
  border-radius: 0.3em;
  border: 1px solid transparent;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  z-index: 1;

  &.selected {
    border: 1px solid rgba(111, 111, 255, 0.8);
  }

  @media only screen and (max-width: 600px) {
    padding: 1vw;
    padding-right: 0.5vw;
    height: 15vw;
    width: 47.5vw;
    font-size: 3.7vw;
    margin: 1.2vw;
    border-radius: 1vw;
    z-index: 1;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding: 1vw 2vw;
    height: 9vw;
    width: 30.5vw;
    font-size: 2vw;
    margin: 1vw;
  }
}
.opacitate {
  opacity: 0.4;
}
.lifeskillNum {
  position: relative;
  padding-top: 0.1em;
  padding-left: 1.35em;
  padding-right: 1.5em;
  border-radius: 2em;
  margin-right: 1em;
  font-size: 0.9em;
  font-family: 'HelveticaNeue', sans-serif;
  color: rgba(0, 0, 0, 0.452);
  z-index: 1;

  &.dwarfStar {
    font-size: 0.8em;
    padding-top: 0.05em;
    padding-left: 1.6em;
    padding-right: 1.2em;
    color: rgba(0, 0, 0, 0.61);
  }

  @media only screen and (max-width: 600px) {
    padding-top: 0vw;
    padding-left: 3.5vw;
    padding-right: 4vw;
    margin-right: 1.5vw;
    font-size: 3vw;

    &.dwarfStar {
      padding-top: 0vw;
      padding-left: 3.7vw;
      padding-right: 4vw;
      margin-right: 1.5vw;
      font-size: 2.7vw;
    }
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    padding-top: 0.3vw;
    padding-left: 2.4vw;
    padding-right: 2vw;
    border-radius: 6vw;
    margin-right: 2vw;
    font-size: 1.9vw;
  }
}
.lifeskillName {
  text-align: start;
  font-size: 0.9em;
  color: white;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;

  @media only screen and (max-width: 600px) {
    font-size: 3.2vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    font-size: 1.8vw;
  }
}
.lifeskillCircularProgress {
  margin-left: 0.2em;
  width: 3.13em;
  height: 3.13em;

  @media only screen and (max-width: 600px) {
    position: absolute;
    width: 9.1vw;
    height: 9.1vw;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    width: 6.25vw;
    height: 7vw;
  }
}
.lifeskillCircularProgressBig {
  right: 0;
  margin-right: 5.5em;
  margin-bottom: 2.2em;
  width: 10em;
  height: 10em;
  transition: opacity 0s ease-in;
  opacity: 0;

  &.show {
    transition: opacity 1s ease-in;
    opacity: 1;
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    margin-right: 13.4vw;
    margin-bottom: 5.5vw;
    width: 21.8vw;
    height: 21.8vw;
  }
  @media only screen and (min-width: 1200px) and (max-width: 2000px) {
    margin-right: 6.15em;
    margin-bottom: 2.4em;
    width: 10em;
    height: 10em;
  }
}
.lifeskillsWorkInProgressTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70em;
  font-size: 0.8em;
  font-family: 'HelveticaNeue', sans-serif;
  color: white;
  opacity: 0.3;
  margin-top: 2em;

  @media only screen and (max-width: 600px) {
    width: 90vw;
    font-size: 4vw;
    margin-top: 7vw;
  }
}

.editIcon {
  cursor: pointer;
  width: 0.8em;
  height: 0.8em;
  color: white;
  margin-left: 0.5em;
  opacity: 0.8;

  @media only screen and (max-width: 600px) {
    width: 3vw;
    height: 3vw;
    margin-left: 2vw;
  }
}

.carousel {
  /deep/ .VueCarousel-pagination {
    .VueCarousel-dot-container {
      margin-top: 0 !important;
      @media only screen and (max-width: 600px) {
        margin-top: -3vw !important;
      }
    }
  }
}
</style>
