<template>
  <div class="DashboardHomeView">
    <div v-if="gettingStartedHasFocus" class="focus-overlay" />

    <TeamInvitation />
    <WidthWrapper set-vars-on-parent />

    <div class="section section--gradient">
      <MemoransierGamesSection />
    </div>

    <AuraBeltSection />

    <div
      v-if="gettingStartedTotalProgress < 1"
      class="gettingStarted section section--gradient"
      :class="{ focus: gettingStartedHasFocus }"
    >
      <GettingStartedSection />
    </div>

    <div class="section section--gradient">
      <IndependentCoursesSection class="Section" />
    </div>

    <UniverseSection />

    <MissionBraindatesRow />

    <TeamsSection />

    <GameLanguageVideoSection />

    <!-- <div class="section section--gradient"> -->
    <!-- <DashboardFunctionalBraintrainingSection class="functionBrainTraining" /> -->
    <!-- </div> -->
    <div class="build">{{ build }}</div>
  </div>
</template>

<translations>
  future:
    title: 'Future Skills'
    title_no: 'Fremtidsferdigheter'
    text: 'Stay ahead of change with the techniques that train your brain to a better day and a safer future'
    text_no: 'Topptrent hjerne i dag - lynrask læring for i morgen'

  allroundknowledge:
    title: 'Allround Knowledge'
    title_no: 'Allmennkunnskap'
    text: 'Stay ahead of change with the insight we all should have'
    text_no: 'Fyll hjernen med klassisk innsikt og nye tanker'

  selfmanagement:
    title: 'Self-management'
    title_no: 'Selvledelse'
    text: 'Train your brain, become a star'
    text_no: 'Tren hjernen så du blir stjernen'

  collections:
    title: 'Collections'
    title_no: 'Samlinger'
    text: 'Explore the skills for a better today and tomorrow - with theory, practice and videos'
    text_no: 'Utforsk ferdighetene for en bedre hverdag og fremtid - med teori, praksis og video'

  categories:
    title: 'Categories'
    title_no: 'Kategorier'
    text: 'Explore Memolife''s ecosystem: 20/100 skills with theory & practice - the rest w/video'
    text_no: 'Utforsk Memolifes økosystem: 20/100 ferdigheter med teori & praksis - resten video'

  viewBy: 'View by'
  viewBy_no: 'Sorter som'
</translations>

<script>
import { mapGetters, mapState } from 'vuex';

import { getLifeskillRangeFromCategoryId } from '@/utils';

import WidthWrapper from '../components/WidthWrapper';
// import DashboardFunctionalBraintrainingSection from '../components/DashboardFunctionalBraintrainingSection';

import MemoransierGamesSection from '../components/MemoransierGamesSection';
import IndependentCoursesSection from '../components/IndependentCoursesSection';
import GettingStartedSection from '../components/GettingStartedSection';
import MissionBraindatesRow from '../components/MissionBraindatesRow/MissionBraindatesRow';

import TeamInvitation from '@/modules/team/TeamInvitation';

import UniverseSection from '../components/UniverseSection';

import TeamsSection from './TeamsSection';

import AuraBeltSection from '../components/AuraBeltSection/AuraBeltSection.vue';

import GameLanguageVideoSection from '../components/GameLanguageVideoSection';

export default {
  components: {
    WidthWrapper,
    // DashboardFunctionalBraintrainingSection,
    MemoransierGamesSection,
    IndependentCoursesSection,
    GettingStartedSection,
    MissionBraindatesRow,
    TeamInvitation,
    UniverseSection,
    TeamsSection,
    AuraBeltSection,
    GameLanguageVideoSection,
  },
  beforeRouteEnter(to, from, next) {
    next(async vm => {
      if (from.name === 'TeamProfilePage') {
        await vm.$nextTick();
        const target = vm.$el.querySelector('.TeamsSection');
        target?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    });
  },
  data() {
    return {
      bottomSectionActiveTab: 'collections',
      build: process.env.GIT_REVISION,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'moduleAuth/profile',
      getJourneyById: 'moduleCourse/getJourneyById',
      gettingStartedTotalProgress: 'moduleApp/gettingStartedTotalProgress',
    }),

    ...mapState({
      courses: state => state.moduleCourse.courses,
    }),

    lifeskillHideMap() {
      return this.$store.state.moduleApp.lifeskillHideMap;
    },

    futureLifeskillIds() {
      return this.$store.state.moduleApp.packages.essentials.lifeskillIds.filter(id => !this.lifeskillHideMap[id]);
    },

    allroundknowledgeLifeskillIds() {
      return this.$store.state.moduleApp.packages.allroundknowledge.lifeskillIds.filter(
        id => !this.lifeskillHideMap[id]
      );
    },

    selfmanagementLifeskillIds() {
      return this.$store.state.moduleApp.packages.selfmanagement.lifeskillIds.filter(id => !this.lifeskillHideMap[id]);
    },

    gettingStartedHasFocus() {
      return this.$store.getters['moduleApp/gettingStartedHasFocus'];
    },

    categories() {
      const categorieIds = Object.keys(this.$store.state.moduleApp.categories);
      return categorieIds.map(categoryId => {
        const lifeskillRange = getLifeskillRangeFromCategoryId(categoryId).join('-');
        return {
          categoryId,
          title: this.$t(`dashboard.general.categoryLabels.${categoryId}`) + ' ' + lifeskillRange,
          lifeskillIds: this.$store.getters['moduleApp/getCategoryById'](categoryId).lifeskills,
        };
      });
    },

    finishedCoursesIds() {
      const finishedCoursesIds = [];

      Object.values(this.courses).forEach(course => {
        const courseStepGroupIds = course.stepGroupIds;

        const isCourseFinished =
          courseStepGroupIds &&
          courseStepGroupIds.length &&
          courseStepGroupIds.every(id => {
            const journey = this.getJourneyById(id);
            const isJourneyFinished = journey.quiz && journey.quiz.done;

            return isJourneyFinished;
          });

        if (isCourseFinished) {
          finishedCoursesIds.push(course.id);
        }
      });

      return finishedCoursesIds;
    },
  },
};
</script>

<style lang="scss" scoped>
.DashboardHomeView {
  overflow-y: auto;
}
.gettingStarted {
  display: flex;
  justify-content: center;
  @media (max-width: 620px) {
    display: block;
  }
}
.section {
  position: relative;
  padding-left: calc(var(--widthWrapperSideWidth) * 1px);
  padding-right: calc(var(--widthWrapperSideWidth) * 1px);
  padding-top: 2.5em;
  padding-bottom: 2em;

  &:empty {
    border: 1px solid red;
    display: none;
  }

  &.disabled::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    // padding-left: 3vw;
    // padding-right: 3vw;
    padding-left: calc(var(--widthWrapperSideWidth) * 0.3px);
    padding-right: calc(var(--widthWrapperSideWidth) * 0.3px);
  }
}

.section--gradient {
}
.section--darker {
}

.Section {
  padding-top: 1.5em;
}

.text {
  color: rgba(white, 0.7);
  line-height: 1.5em;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(white, 0.05); // rgba(133, 222, 185, 0.2);
  margin-bottom: 1em;
}
.tabs {
  border-bottom: 1px solid rgba(white, 0.05); // rgba(133, 222, 185, 0.2);
  display: flex;
}
.Tab {
  padding: 1em 0;

  &.active {
    border-bottom: 3px solid #31d5b7;
    font-weight: 600;
  }

  &:not(:first-child) {
    margin-left: 1.5em;
  }
}
.tabs__label {
  padding: 1em 0;
  color: rgba(white, 0.3);
}

.team-section {
  display: flex;
  justify-content: space-between;

  @media (max-width: 900px) {
    flex-direction: column;
  }

  div {
    margin-right: 10px;
  }

  .TeamChallengeAdminDashboard,
  .TeamChallengeUserView {
    flex-grow: 1;
  }

  .TeamLeaderboardFull {
    height: 353px;
    overflow: scroll;
    padding: 1em;
    border-radius: 8px;
    background-color: #18194c;
    min-width: 300px;
  }
}

.focus-overlay {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(20, 19, 69, 0.78);
}

.focus {
  z-index: 6;
  background-color: #0a1335;
}

.build {
  text-align: right;
  font-family: monospace;
  opacity: 0.5;
  padding-right: 1em;
}
</style>
