<template>
  <div class="Belt">
    <Avatar :profile="true" class="avatarIcon" />
    <AvatarRive class="belt" />
  </div>
</template>

<script>
import Avatar from '@/components/Avatar';
import AvatarRive from '@/components/AvatarRive.vue';

export default {
    components: { Avatar, AvatarRive },
    props: {
        color: {
            type: String,
            default: ''
        },
        avatar: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.Belt {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // border-radius: 50%;

    $size: 3em;
    width: $size;
    height: $size;
}
.Avatar {
    position: absolute;
}
.belt {
    z-index: 1;
    position: absolute;
    top: 10px;
    left: 4px;
}
</style>
