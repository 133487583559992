<template>
  <div class="HabitsMissionSectionPopup">
    <div class="habitsMissionTitle">
      {{ $t('HabitsTitle') }}
    </div>

    <FullWidthHorizontalScroll ref="fullWidthHorizontalScroll" class="widthHorizontal">
      <div class="missionsContainer missionsLeftPadding">
        <div class="missionsWrapperContainer">
          <div class="missionsTitle"> {{ $t('mentalHygiene') }} </div>
          <div class="mentalHygieneMissions">
            <HabitMissionItem
              v-for="mission of mentalHygieneMissions"
              :key="mission.id"
              :mission="mission"
              :viewing-mission-id="viewingMissionId"
              :selected="isMissionSelected(mission.id)"
              @select="viewMission(mission.id)"
            />
          </div>
        </div>
      </div>

      <div class="missionsContainer">
        <div class="missionsWrapperContainer">
          <div class="missionsTitle"> {{ $t('memoryGames') }} </div>
          <div class="mentalHygieneMissions">
            <HabitMissionItem
              v-for="mission of gameMissions"
              :key="mission.id"
              :mission="mission"
              :selected="isMissionSelected(mission.id)"
              :viewing-mission-id="viewingMissionId"
              @select="viewMission(mission.id)"
            />
          </div>
        </div>
      </div>
    </FullWidthHorizontalScroll>

    <HabitMissionDetails v-if="viewingMissionId" :mission-id="viewingMissionId">
      <div
        v-if="isTeamAdmin"
        slot="button"
        class="selectMissionButton"
        :class="{ selected: isMissionSelected(viewingMissionId) }"
        @click="selectMission(viewingMissionId)"
      >
        {{ isMissionSelected(viewingMissionId) ? $t('deselect') : $t('select') }}
      </div>
    </HabitMissionDetails>
    <div v-if="showDoneButton" class="doneButton" @click="$emit('close')">{{ $t('done') }}</div>
  </div>
</template>

<translations>
  title: 'Choose a mission for your brain dates: '
  title_no: 'Velg et oppdrag for dine hjernedates:'
  select: 'Select'
  select_no: 'Velg'
  deselect: 'Deselect'
  deselect_no: 'Velg bort'

  mentalHygiene: 'Mental   Hygiene'
  mentalHygiene_no: 'Mentalhygiene'

  memoryGames: 'Memory Games'
  memoryGames_no: 'Huskespill'

  3min: '3 minutes a day'
  3min_no: '3 minutter om dagen'

  HabitsTitle: 'Habit Missions'
  HabitsTitle_no: 'Oppdrag: Vaner'

  done: 'Done'
  done_no: 'Ferdig'
</translations>

<script>
import FullWidthHorizontalScroll from '@/modules/dashboard/components/FullWidthHorizontalScroll'
import HabitMissionItem from './HabitMissionItem'
import HabitMissionDetails from './HabitMissionDetails'
import { habitsMissionArray, getHabitMissionById } from '@/store/modules/module-app/missions.js'
import { getOffsetLeft } from '@/utils'
import apollo from '@/apollo-client'
import gql from 'graphql-tag'

export default {
  components: { FullWidthHorizontalScroll, HabitMissionItem, HabitMissionDetails },
  data() {
    return {
      viewingMissionId: '',
      teamLeaderboardFullsList: [],
      teamMembers: [],
      showDoneButton: false,
    }
  },
  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    activeMissions() {
      //
      return this.$store.state.moduleApp.memolife.selectedHabitMissions
    },
    selectedMissions() {
      return this.$store.getters['moduleApp/selectedHabitMissions']()
    },
    teamsList() {
      return this.$store.state.moduleApp.memolife.teamsList
    },
    habitMissionBraindates() {
      return this.$store.getters['moduleApp/getHabitBraindates']
    },
    // viewingMission() {
    //   return getHabitMissionById(this.viewingMissionId)
    // },
    mentalHygieneMissions() {
      return habitsMissionArray.filter(mission => mission.type === 'mentalHygiene')
    },
    gameMissions() {
      return habitsMissionArray.filter(mission => mission.type === 'game')
    },
    isTeamAdmin() {
      const me = this.$store.getters['moduleAuth/profile'].id
      if (this.selectedTeamId === '0') {
        return true
      } else {
        return this.teamMembers.filter(m => m.userId === me && m.isAdmin).length > 0
      }
    },
  },
  mounted() {
    this.fetchAllTeamsAndMembers()

    const selectMissionsIds = this.selectedMissions.map(m => m.missionId)
    const firstSelectedMission = habitsMissionArray.find(mission => {
      return selectMissionsIds.includes(mission.id)
    })
    if (firstSelectedMission) {
      this.viewMission(firstSelectedMission.id)
    } else {
      this.viewMission(this.mentalHygieneMissions[0].id)
    }

    let x = this.$refs.fullWidthHorizontalScroll.$el.querySelector('.HabitMissionItem.selected')
    if (x) {
      this.$refs.fullWidthHorizontalScroll.$el.scrollLeft =
        getOffsetLeft(x, this.$refs.fullWidthHorizontalScroll.$el) - 60
    }
  },
  methods: {
    async fetchAllTeamsAndMembers() {
      const myTeamId = parseInt(this.selectedTeamId)
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLeaderboard($teamId: Int!) {
            __typename
            teamsList {
              id
              name
              tags
            }
            teamLeaderboardFullsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
            teamMembersList(condition: { teamId: $teamId }, orderBy: DISPLAY_NAME_ASC) {
              avatar
              displayName
              teamId
              teamName
              isAdmin
              userId
            }
          }
        `,
        variables: { teamId: myTeamId },
      })

      const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals)
      this.teamLeaderboardFullsList = result.data.teamLeaderboardFullsList.sort(byLifepoints)
      this.$store.commit('moduleApp/setTeamList', result.data.teamsList)
      this.teamMembers = result.data.teamMembersList
    },

    isMissionSelected(missionId) {
      if (this.selectedTeamId === '0') {
        return (
          this.activeMissions.find(mission => {
            return mission.missionId === missionId
          })?.isSelected ?? false
        )
      } else {
        return this.selectedMissions.some(mission => {
          return mission.missionId === missionId
        })
      }
    },
    isMentalHygiene(missionId) {
      return this.mentalHygieneMissions.find(mission => {
        return mission.id === missionId ? true : false
      })
    },
    isMissionAdded(missionId) {
      const hasMission = this.activeMissions.filter(mission => mission.missionId === missionId)
      return hasMission.length
    },

    selectMission(missionId) {
      const mission = getHabitMissionById(missionId)
      const missionName = mission.name
      const missionName_no = mission.name_no

      if (this.selectedTeamId === '0') {
        if (this.isMissionAdded(missionId)) {
          if (this.isMissionSelected(missionId)) {
            this.$store.dispatch('moduleApp/deselectHabitMission', missionId)
          } else {
            this.$store.dispatch('moduleApp/selectHabitMission', missionId)
          }
        } else {
          const timestamp = Date()
          const days = []
          let isSelected = true
          this.$store.dispatch('moduleApp/addHabitMission', {
            missionId,
            timestamp,
            days,
            isSelected,
            missionName,
            missionName_no,
          })
        }
      } else {
        if (this.isMissionSelected(missionId)) {
          this.removeTag(missionId)
        } else {
          this.addTag(missionId)
          console.log(this.teamsList, ' teamlist')
        }
      }
      this.showDoneButton = true
    },
    viewMission(mission) {
      console.log('viewMission', mission)
      this.viewingMissionId = mission
    },

    removeTag(missionId) {
      const team = this.teamsList.find(t => t.id === parseInt(this.selectedTeamId))
      const tags = [...team.tags]
      const idx = tags.indexOf('habitMission:' + missionId)
      tags.splice(idx, 1)

      apollo
        .mutate({
          mutation: gql`
            mutation UpdateTeamMutation($id: Int!, $name: String!, $tags: [String!]) {
              __typename
              updateTeam(input: { _id: $id, _tags: $tags, _name: $name }) {
                results {
                  teamId
                  teamName
                  teamTags
                }
              }
            }
          `,
          variables: {
            id: team.id,
            name: team.name,
            tags,
          },
        })
        .then(() => this.fetchAllTeamsAndMembers())
    },

    addTag(missionId) {
      const team = this.teamsList.find(t => t.id === parseInt(this.selectedTeamId))
      const tag = 'habitMission:' + missionId
      const tags = [...(team.tags ?? []), tag]
      apollo
        .mutate({
          mutation: gql`
            mutation UpdateTeamMutation($id: Int!, $name: String!, $tags: [String!]) {
              __typename
              updateTeam(input: { _id: $id, _tags: $tags, _name: $name }) {
                results {
                  teamId
                  teamName
                  teamTags
                }
              }
            }
          `,
          variables: {
            id: team.id,
            name: team.name,
            tags: tags,
          },
        })
        .then(() => this.fetchAllTeamsAndMembers())
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

$title-font: 'Raleway', sans-serif;
$text-font: Source Sans Pro;
$camelcase-spacing: 0.02em;
$color-white: #d6d4ef;

.widthHorizontal {
  padding: 0;
  /deep/ .sideFiller {
    width: 0;
  }
}
.HabitsMissionSectionPopup {
  z-index: 1000;
  background-color: #130f44;
  width: 70em;
  border-radius: 1em;
  overflow: hidden;
  overflow-y: scroll;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-bottom: 4em;

  @media only screen and (max-width: 600px) {
    z-index: 1000;
    width: 100vw;
    border-radius: 2vw;
    padding-bottom: 20vw;
  }
}

.habitsMissionTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  color: $color-white;
  font-size: 140%;
  font-family: $title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  margin-bottom: 1.2em;
  margin-top: 1.2em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 3vw;
  }
}

.missionsContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 600px) {
  }

  .missionsWrapperContainer {
    padding: 1.4em;
    padding-top: 1em;
    background-color: #130f44;
    border-radius: 1em;
    margin-top: 0.3em;
    margin-right: 1em;

    @media only screen and (max-width: 600px) {
      font-size: 4.7vw;
      margin-bottom: 3vw;
      margin-top: 1vw;
      margin-right: 5vw;
      padding: 2vw;
      padding-top: 4vw;
    }
  }
  .missionsTitle {
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    color: #d6d4ef;
    margin-bottom: 0.3em;

    @media only screen and (max-width: 600px) {
      font-size: 4.7vw;
      margin-bottom: 3vw;
    }
  }
  .mentalHygieneMissions {
    display: flex;
    flex-direction: row;
  }
}
.missionsLeftPadding {
  margin-left: calc(var(--widthWrapperSideWidth) * 1px);
  @media only screen and (max-width: 600px) {
    margin-left: 0;
  }
}

.selectMissionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3em;
  background: #92f1f6;
  font-weight: 600;
  color: rgb(0, 0, 0);
  width: 10em;
  height: 3em;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    width: 30vw;
    height: 12vw;
    border-radius: 1.5vw;
  }

  &.selected {
    background: #9daef791;
    color: white;
  }
}
.doneButton {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3em;
  background-color: rgba(56, 102, 181, 0.8);
  color: white;
  font-size: 1.4em;
  cursor: pointer;

  &:hover {
    background-color: rgba(37, 68, 121, 0.8);
    color: rgba(255, 255, 255, 0.564);
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
    height: 14vw;
  }
}
</style>
