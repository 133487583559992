<template>
  <div class="TeamInvitation" :class="{ empty: invitations.length === 0 }">
    <ul v-if="invitations">
      <li
        v-for="i of invitations"
        :key="i.teamId">
        {{ $t('invited') }} <i>{{ i.teamName }}</i>
        <br>
        <button
            @click="accept(i.id)">{{ $t('join') }}</button>
       &nbsp;
        <button
            @click="decline(i.id)">{{ $t('decline') }}</button>
      </li>
    </ul>
  </div>
</template>

<translations>
  invited: You have been invited to join team
  invited_no: Du har blitt invitert til å bli med team

  join: Join
  join_no: Bli med

  decline: Decline
  decline_no: Nei takk
</translations>


<script>
import apollo from '@/apollo-client';
import gql from 'graphql-tag';


export default {
  data: function() {
    return {
      invitations: [],
    }
  },

  props: {},

  mounted() {
    this.fetch();
  },

  methods: {
    decline(id) {
      this.answer(id, "declined");
    },
    accept(id) {
      this.answer(id, "accepted");
    },

    async answer(id, status) {
      await apollo.mutate({
        mutation: gql`
          mutation AcceptInvitation($id: UUID!, $status: String!) {
            __typename
            updateTeamInvitation(input: {patch: {status: $status}, id: $id}) {
              teamInvitation {
                status
              }
            }
          }
        `,
        variables: {id, status},
      });
      this.fetch();
    },

    async fetch() {
      const result = await apollo.query({
        fetchPolicy: "no-cache",
        query: gql`
          query Invitations ($userId: String!){
            teamInvitationsList(condition: {status: "invited", userId: $userId}) {
              id
              teamId
              teamName
            }
          }
        `,
        variables: { userId: this.userId },
      });
      this.invitations = result.data.teamInvitationsList;
    }
  },

  computed: {
    userId() {
      return this.$store.getters['moduleAuth/profile'].id;
    }
  },

  filters: {},
}
</script>

<style lang="scss" scoped>
.TeamInvitation {
  background-color: $standardButtonColor;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
  text-align: center;

  ul {
    list-style: none;
  }
}

.TeamInvitation.empty {
  display: none;
}
</style>
