<template>
  <div class="SkillsMissionSectionPopup">
    <div class="skillsMissionTitle">
      {{ $t('SkillsTitle') }}
    </div>

    <div>
      <FullWidthHorizontalScroll ref="fullWidthHorizontalScroll" class="widthHorizontal">
        <div class="missionsContainers missionsLeftPadding">
          <SkillsMissionItem
            v-for="(mission, i) of missions"
            :key="i"
            :mission="mission"
            :selected="activeMissionId === mission.id"
            :viewing="viewingMission === mission.id"
            :not-selected="!!activeMissionId"
            @click.native="viewMission(mission.id)"
          >
            <div
              v-if="isTeamAdmin && viewingMission === mission.id"
              class="selectMissionButton"
              :class="{ selected: activeMissionId === mission.id, show: viewingMission === mission.id }"
              @click="selectMission(mission.id)"
            >
              {{ activeMissionId === mission.id ? $t('selected') : $t('select') }}
            </div>
          </SkillsMissionItem>
        </div>
      </FullWidthHorizontalScroll>
    </div>
    <SkillsMissionDetails v-if="viewingMission" :mission-id="viewingMission" @close="$emit('close')" />
    <div v-if="showDoneButton" class="doneButton" @click="$emit('close')">{{ $t('done') }}</div>
  </div>
</template>

<translations>
  title: 'Choose a mission for your brain dates: '
  title_no: 'Velg et oppdrag for dine hjernedates:'
  select: 'Select'
  select_no: 'Velg'
  selected: 'Selected'
  selected_no: 'Valgt'

  40title: 'Innertier'
  70title: 'Mentalhygiene'
  50title: 'Sosial stjerne'

  SkillsTitle: 'Skill Missions'
  SkillsTitle_no: 'Oppdrag: Ferdigheter'

  done: 'Done'
  done_no: 'Ferdig'
</translations>

<script>
import SkillsMissionItem from './SkillsMissionItem'
import FullWidthHorizontalScroll from '@/modules/dashboard/components/FullWidthHorizontalScroll'
import { skillsMissionArray } from '@/store/modules/module-app/missions.js'
import SkillsMissionDetails from './SkillsMissionDetails'
import apollo from '@/apollo-client'
import gql from 'graphql-tag'

export default {
  components: {
    SkillsMissionItem,
    SkillsMissionDetails,
    FullWidthHorizontalScroll,
  },
  data() {
    return {
      viewingMission: '',
      teamLeaderboardFullsList: [],
      teamMembers: [],
      showDoneButton: false,
    }
  },
  computed: {
    activeMissionId() {
      if (this.selectedTeamId === '0') {
        return this.$store.getters['moduleApp/mission']()
      } else {
        return this.$store.getters['moduleApp/selectedTeamSkillMission']
      }
    },
    activeMission() {
      return this.missions[this.activeMissionId]
    },
    missions() {
      return skillsMissionArray
    },
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    teamsList() {
      return this.$store.state.moduleApp.memolife.teamsList
    },
    myTeams() {
      return this.teamLeaderboardFullsList
        .filter(item => this.teamsList.find(t => t.id === item.teamId))
        .reduce((acc, current) => {
          const team = this.teamsList.find(t => t.id === current.teamId)
          if (!acc.find(item => item.teamName === team.name)) {
            acc.push({ ...current, teamName: team.name })
          }
          return acc
        }, [])
    },
    isTeamAdmin() {
      const me = this.$store.getters['moduleAuth/profile'].id
      if (this.selectedTeamId === '0') {
        return true
      } else {
        return this.teamMembers.filter(m => m.userId === me && m.isAdmin).length > 0
      }
    },
  },
  mounted() {
    this.fetchAllTeamsAndMembers()
    this.viewMission(this.activeMissionId)
    let x = this.$refs.fullWidthHorizontalScroll.$el.querySelector('.SkillsMissionItem.selected')
    if (x) {
      this.$refs.fullWidthHorizontalScroll.$el.scrollLeft = x.offsetLeft - 60
    }
  },
  methods: {
    async fetchAllTeamsAndMembers() {
      const myTeamId = parseInt(this.selectedTeamId)
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLeaderboard($teamId: Int!) {
            __typename
            teamsList {
              id
              name
              tags
            }
            teamLeaderboardFullsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
            teamMembersList(condition: { teamId: $teamId }, orderBy: DISPLAY_NAME_ASC) {
              avatar
              displayName
              teamId
              teamName
              isAdmin
              userId
            }
          }
        `,
        variables: { teamId: myTeamId },
      })

      const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals)
      this.teamLeaderboardFullsList = result.data.teamLeaderboardFullsList.sort(byLifepoints)
      this.$store.commit('moduleApp/setTeamList', result.data.teamsList)
      this.teamMembers = result.data.teamMembersList
    },

    selectMission(mission) {
      if (this.activeMissionId !== mission) {
        if (this.selectedTeamId !== '0') {
          this.addTag('skillsMission:' + mission)
        } else {
          this.$store.dispatch('moduleApp/setMission', mission)
        }
        this.$emit('show-braindates')
        this.showDoneButton = true
      }
    },
    viewMission(mission) {
      this.viewingMission = mission
    },

    addTag(tag) {
      let team = this.teamsList.find(t => t.id === parseInt(this.selectedTeamId))
      console.log(team, ' team')
      if (team) {
        const tags = [...(team.tags ?? [])]
        var skillsMissionTag = tags.find(t => t.includes('skillsMission:'))
        if (skillsMissionTag) {
          const idx = tags.indexOf(skillsMissionTag)
          tags[idx] = tag
        } else {
          tags.push(tag)
        }

        apollo
          .mutate({
            mutation: gql`
              mutation UpdateTeamMutation($id: Int!, $name: String!, $tags: [String!]) {
                __typename
                updateTeam(input: { _id: $id, _tags: $tags, _name: $name }) {
                  results {
                    teamId
                    teamName
                    teamTags
                  }
                }
              }
            `,
            variables: {
              id: team.id,
              name: team.name,
              tags: tags,
            },
          })
          .then(() => this.fetchAllTeamsAndMembers())
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

$title-font: 'Raleway', sans-serif;
$camelcase-spacing: 0.02em;
$color-white: #d6d4ef;

.widthHorizontal {
  padding: 0;
  padding-left: 2em;
  overflow-y: visible;
  /deep/ .sideFiller {
    width: 0;
  }
}
.SkillsMissionSectionPopup {
  width: 70em;
  z-index: 1000;
  background-color: #0f1340;
  overflow: hidden;
  overflow-y: scroll;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-bottom: 3em;

  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
    padding-bottom: 20vw;
  }
}

.skillsMissionTitle {
  display: flex;
  justify-content: center;
  width: 100%;
  color: $color-white;
  font-size: 140%;
  font-family: $title-font;
  letter-spacing: $camelcase-spacing;
  font-weight: 600;
  margin-bottom: 1.2em;
  margin-top: 1.2em;

  @media only screen and (max-width: 600px) {
    margin-bottom: 3vw;
  }
}

.selectMissionButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3em;
  background: #9daef791;
  font-weight: 600;
  color: rgb(0, 0, 0);
  visibility: hidden;
  width: 10em;
  height: 3em;

  &.show {
    visibility: visible;
  }
  &.selected {
    background: #92f1f6;
    color: rgba(0, 0, 0, 0.908);
  }
}

.missionsContainers {
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
    width: 93vw;
    margin-left: 0;
    margin-top: 1vw;
  }
}
.missionsLeftPadding {
  margin-left: calc(var(--widthWrapperSideWidth) * 1px);
}
.doneButton {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3em;
  background-color: rgba(56, 102, 181, 0.8);
  color: white;
  font-size: 1.4em;
  cursor: pointer;

  &:hover {
    background-color: rgba(37, 68, 121, 0.8);
    color: rgba(255, 255, 255, 0.564);
  }

  @media only screen and (max-width: 600px) {
    font-size: 4.5vw;
    height: 14vw;
  }
}
</style>
