<template>
  <div class="TeamsSection">
    <div v-if="!teamSectionUnlocked" class="title"
      >{{ $t('title1') }} <br />
      {{ $t('title2') }}</div
    >

    <div v-if="!teamSectionUnlocked" class="lockAnimationContainer">
      <!-- <div class="unlockTitle">Unlock your social brain</div> -->
      <div class="lockAnimationButton" @click="generateTeams()"> </div>
      <canvas ref="teamsLockCanvas" class="riveContainer" width="700px" height="250px" />
    </div>

    <div v-if="teamSectionUnlocked" class="teamsContainer">
      <img class="starsImage" src="./assets/images/stars-carousel.svg" alt="" />
      <Flicking
        ref="flicking"
        :key="myTeams.length"
        class="teamsCarouselContainer"
        :options="{ circular: false, panelsPerView: -1 }"
        @changed="onChange"
        @ready="updateTransform"
        @move="updateTransform"
      >
        <TeamItem
          v-for="team in myTeams"
          :key="team.teamId"
          :team="team"
          :selected="selectedTeamId === team.teamId"
          @click.native="goToTeamProfilePage(team.teamId)"
        />

      </Flicking>
      <!-- <div v-if="teamSectionUnlocked" class="title">{{ selectedTeamName }}</div> -->
      <div class="viewAddContainer">
        <div class="viewAll" @click="gotoLeaderboard()">{{ $t('viewAll') }}</div>
        <div class="viewAddSeparator">|</div>
        <div class="viewAll" @click="openAddTeamPopup()">{{ $t('addTeam') }}</div>
        <div class="viewAddSeparator">|</div>
        <div class="viewAll">{{ $t('teamMagic') }}</div>
      </div>
    </div>
  </div>
</template>

<translations>
    title1: Unlock your social brain
    title1_no: Frigjør din sosiale hjerne
    title2: Grow better together
    title2_no: Bli bedre sammen
    viewAll: All teams
    viewAll_no: Alle team
    teamMagic: Team magic
    teamMagic_no: Team-magi
    top5: Ranking
    top5_no: Ranking
    myTeams: My teams
    myTeams_no: Mine team
    members: Members
    members_no: Medlemmer
    open: Open
    open_no: Åpne
    addTeam: New team
    addTeam_no: Nytt team
    me: Me
    me_no: Meg
    family: Family
    family_no: Familie
    friends: Friends
    friends_no: Venner
    work: Work
    work_no: Jobb

</translations>

<script>
import apollo from '@/apollo-client'
import gql from 'graphql-tag'
import uniqBy from 'lodash/uniqBy'
import addTeamPopup from '@/modules/team/TeamCreate'
import RiveFile from '@/assets/rive/teams-lock-dashboard.riv'
const rive = require('rive-js')
import { Flicking } from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'
import { habitsMissionArray } from '@/store/modules/module-app/missions.js'
import _ from 'lodash'
import TeamItem from './TeamItem.vue'

export default {
  components: { Flicking, TeamItem },
  data() {
    return {
      teamLeaderboardFullsList: [],
      tabSelected: 'myTeams',
      r: null,
      teamBraindates: [],
      selectedTeamName: 'Me',
      teamMemberRankingsList: [],
    }
  },
  computed: {
    onlyUniqueMembers() {
      return uniqBy(this.teamLeaderboardFullsList, 'userId')
    },

    top5Members() {
      return this.onlyUniqueMembers.slice(0, 5)
    },
    selectedHabitMissions() {
      return this.$store.state.moduleApp.memolife.selectedHabitMissions
    },

    myTeams() {
      const myId = this.$store.getters['moduleAuth/profile'].id
      const soloTeam = {
        braindates: 100000,
        crystals: 100000,
        tags: ['Solo', 'skillsMission:'],
        teamId: 0,
        teamName: this.$t('me'),
        userId: myId,
      }
      const myMembers = this.teamLeaderboardFullsList
        .filter(item => this.teamsList.find(t => t.id === item.teamId))
        .reduce((acc, current) => {
          const team = this.teamsList.find(t => t.id === current.teamId)
          if (!acc.find(item => item.teamName === team.name)) {
            acc.push({ ...current, teamName: team.name, tags: team.tags })
          }
          return acc
        }, [])
      myMembers.unshift(soloTeam)
      const teams = this.groupTeams(myMembers)

      return teams
    },
    teamsList() {
      return this.$store.state.moduleApp.memolife.teamsList
    },
    top5Teams() {
      return this.myTeams.slice(0, 5)
    },
    teamSectionUnlocked() {
      return this.$store.state.moduleApp.memolife.teamSectionUnlocked
    },
    hasTeams() {
      return this.top5Teams.length > 0
    },
    profile() {
      return this.$store.getters['moduleAuth/profile']
    },
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    isTeamAdmin() {
      const me = this.$store.getters['moduleAuth/profile'].id
      return this.members.filter(m => m.userId === me && m.isAdmin).length > 0
    },
  },
  async mounted() {
    // this.$store.dispatch('moduleApp/unlockTeamSection', false);
    await this.addAllHabitMissions()
    await this.fetchAllTeamsAndMembers()
    await this.fetchTeamBraindates()
    if (!this.teamSectionUnlocked) {
      this.setRive()
    } else {
      this.moveToInitialIndex()
    }
  },
  beforeDestroy() {
    if (this.r != null) {
      this.r.stop()
      this.r.unsubscribeAll()
      this.r = null
    }
  },
  methods: {
    async fetchAllTeamsAndMembers() {
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamLeaderboard {
            __typename
            teamsList {
              id
              name
              tags
            }
            teamLeaderboardFullsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
            teamMemberRankingsList {
              avatar
              braindates
              crystals
              displayName
              teamId
              teamName
              userId
            }
          }
        `,
      })

      const byLifepoints = (a, b) => b.braindates + b.crystals - (a.braindates + a.crystals)
      this.teamLeaderboardFullsList = result.data.teamLeaderboardFullsList.sort(byLifepoints)
      this.$store.commit('moduleApp/setTeamList', result.data.teamsList)
      this.teamMemberRankingsList = result.data.teamMemberRankingsList
    },

    async fetchTeamBraindates() {
      const result = await apollo.query({
        fetchPolicy: 'no-cache',
        query: gql`
          query TeamBraindates($lifeskill: String) {
            teamBraindatesList(condition: { lifeskillNumber: $lifeskill }) {
              teamName
              teamId
              lifeskillNumber
              braindates
              userId
              stepId
            }
          }
        `,
        variables: { lifeskill: '70' },
      })

      this.teamBraindates = result.data.teamBraindatesList
    },

    setTags(teamId, tags, name) {
      apollo
        .mutate({
          mutation: gql`
            mutation UpdateTeamMutation($id: Int!, $name: String!, $tags: [String!]) {
              __typename
              updateTeam(input: { _id: $id, _name: $name, _tags: $tags }) {
                results {
                  teamId
                  teamTags
                }
              }
            }
          `,
          variables: {
            id: teamId,
            name,
            tags: tags,
          },
        })
        .then(() => this.fetchAllTeamsAndMembers())
    },
    setRive() {
      this.r = new rive.Rive({
        src: RiveFile,
        canvas: this.$refs.teamsLockCanvas,
        autoplay: true,
        layout: new rive.Layout({ fit: 'fill', alignment: 'center' }),
        onload: () => {},
      })
    },

    gotoLeaderboard() {
      this.$emit('signal', 'close')
      this.$router.push({
        name: 'Leaderboard',
      })
    },
    goToTeamProfilePage(id) {
      if (id === this.selectedTeamId) {
        if (id !== '0') {
          this.$router.push({
            name: 'TeamProfilePage',
            params: { teamId: `${id}` },
          })
        }
      } else {
        const teamIndex = this.myTeams.findIndex(team => team.teamId === id)
        this.$refs.flicking.moveTo(teamIndex)
      }
    },
    async openAddTeamPopup() {
      const result = await this.$modal
        .open({
          component: addTeamPopup,
          target: 'app-modal',
        })
        .waitDone()
      if (result === 'cancel') {
        await this.fetchAllTeamsAndMembers()
        this.moveToInitialIndex()
      }
    },
    async generateTeams() {
      this.awardBraindate()
      const allTags = ['Family', 'Friends', 'Work']
      const lastNameInitial = this.profile.last_name ? this.profile.last_name.substring(0, 1) : null
      const firstNameInitial = this.profile.first_name ? this.profile.first_name : ''
      const initials = lastNameInitial
        ? firstNameInitial.substring(0, 1) + lastNameInitial.toUpperCase()
        : firstNameInitial.substring(0, 2).toUpperCase()

      for (let tag of allTags) {
        const name = initials + ' ' + this.$t(tag.toLocaleLowerCase())
        const result = await apollo.mutate({
          mutation: gql`
            mutation CreateTeam($teamName: String!) {
              __typename
              createTeam(input: { teamName: $teamName }) {
                results {
                  name
                  teamId
                }
              }
            }
          `,
          variables: { teamName: name },
        })
        const team = result.data.createTeam.results[0]
        this.setTags(team.teamId, [tag, 'skillsMission:'], name)
      }
      await this.fetchAllTeamsAndMembers()
      this.$store.dispatch('moduleApp/unlockTeamSection', true)
    },

    awardBraindate() {
      this.$store.dispatch(
        'moduleApp/registerBraindate',
        {
          context: 'course',
          lifeskillNumber: '81',
        },
        { root: true }
      )
    },

    updateTransform: e => {
      e.currentTarget.panels.forEach(panel => {
        const progress = Math.min(4, Math.abs(panel.progress))
        const dir = panel.progress < 0 ? -1 : 1
        const sizeFactor = panel.size / 150 // Assumes all panels are the same size
        const xx = Math.pow(Math.abs(panel.progress), 2) * sizeFactor
        panel.element.style.opacity = 1.2 - progress / 3

        panel.element.style.transform = `translate(${-xx * dir * 1}em, ${progress * 2}em) scale(${1 - progress / 4})`
        panel.element.style.setProperty('--labelOpacity', 1 - progress)
      })
    },

    onChange(e) {
      const team = this.myTeams[e.index]

      this.selectedTeamName = team.teamName
      this.$store.dispatch('moduleApp/selectTeamId', team.teamId)
    },

    addAllHabitMissions() {
      const selectedHabitMissions = [...this.selectedHabitMissions]
      for (let i = 0; i < habitsMissionArray.length; i++) {
        let habitMission = habitsMissionArray[i]
        let missionExists = selectedHabitMissions.some(mission => mission.missionId === habitMission.id)
        if (!missionExists) {
          const missionId = habitMission.id
          const missionName = habitMission.name
          const missionName_no = habitMission.name_no
          const timestamp = Date()
          const days = []
          let isSelected = false
          this.$store.dispatch('moduleApp/addHabitMission', {
            missionId,
            timestamp,
            days,
            isSelected,
            missionName,
            missionName_no,
          })
        }
      }
    },

    groupTeams(myMembers) {
      const groupedTeams = _.chain(myMembers)
        .groupBy('teamId')
        .map((members, teamId) => {
          return {
            teamId,
            teamName: members[0].teamName,
            lifePoints: members.reduce((accumulator, member) => {
              return accumulator + (member.braindates + member.crystals)
            }, 0),
            memberCount: members.length,
            tags: members[0].tags,
          }
        })
        .value()
      const byLifepoints = (a, b) => Math.floor(b.lifePoints / b.memberCount) - Math.floor(a.lifePoints / a.memberCount)
      return groupedTeams.sort(byLifepoints)
    },
    moveToInitialIndex() {
      const teamIndex = this.myTeams.findIndex(team => team.teamId === this.selectedTeamId)
      this.$refs.flicking.moveTo(teamIndex)
    },
  },
}
</script>

<style lang="scss" scoped>
.TeamsSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.riveContainer {
  @media only screen and (max-width: 600px) {
    width: 120vw;
  }
}
.lockAnimationContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .unlockTitle {
    font-size: 1.5em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #bec2e5;

    @media only screen and (max-width: 600px) {
      font-size: 4vw;
    }
  }
  .lockAnimationButton {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.3em;
    height: 4.3em;
    border-radius: 3em;
    margin-bottom: 4.5em;
    cursor: pointer;
    z-index: 2;
    font-size: 1.1em;
    font-weight: 600;

    @media only screen and (max-width: 600px) {
      font-size: 3.3vw;
      width: 15vw;
      height: 15vw;
      margin-bottom: 13.5vw;
    }
  }
}

.title {
  font-size: 1.5em;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  color: #d6d4ef;
  margin: 0;
  margin-top: 1.1em;

  @media only screen and (max-width: 600px) {
    font-size: 4.7vw;
    margin-top: 5vw;
    margin-bottom: 0;
  }
}
.teamsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 70em;
  margin-top: 2em;
  margin-bottom: 6em;
  // margin-left: calc(var(--widthWrapperSideWidth) * 1px);

  @media (max-width: 620px) {
    width: 95vw;
    margin-bottom: 6vw;
    margin-top: 0;
  }
  @media (min-width: 620px) and (max-width: 1200px) {
    width: 95vw;
    margin-left: calc(var(--widthWrapperSideWidth) * 0.3px);
  }
}

.starsImage {
  position: absolute;
  z-index: 0;
  width: 35em;
  @media only screen and (max-width: 600px) {
    width: 100vw;
  }
}

.teamsCarouselContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4em;
  width: 70em;
  height: 12em;

  @media (max-width: 620px) {
    justify-content: flex-start;
    width: 100vw;
    height: 35vw;
  }
}

.viewAddContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0.4em;
  // align-items: center;
  @media only screen and (max-width: 600px) {
    margin-top: 0;
  }
}
.viewAddSeparator {
  font-size: 1.2em;
  font-weight: 100;
  color: #6067a5;
  padding: 0 0.6em;
  opacity: 0.5;

  @media only screen and (max-width: 600px) {
    padding: 0 2vw;
  }
}
.viewAll {
  font-size: 0.9em;
  font-weight: 500;
  color: #6067a5;
  align-self: center;
  padding: 0 0.6em;
  cursor: pointer;
  white-space: nowrap;
  opacity: 0.9;

  &:hover {
    color: rgb(102, 191, 255);
  }

  @media only screen and (max-width: 600px) {
    font-size: 3vw;
  }
}
</style>
