<template>
  <ThumbBase
    class="GameItem"
    :image="image"
    :title="$t(`memogenius.games.${gameId}.title`)"
    :subtitle="$t(`memogenius.games.${gameId}.subtitle`)"
    @click="gotoGame()">
    <div
      v-if="highestCompletedLevelInfo"
      slot="tags"
      class="highestCompletedLevelInfo">
      <div class="">
        {{ $t('level') }} {{ highestCompletedLevelInfo.levelNumber }}.{{
          highestCompletedLevelInfo.sublevelNumber
        }}
      </div>
      <div
        v-if="highestCompletedLevelInfo.stars"
        class="stars">
        <svgicon
          v-for="i in 3"
          :key="i"
          class="star"
          :class="[i <= highestCompletedLevelInfo.stars ? 'is-full' : 'is-empty']"
          icon="star" />
      </div>
    </div>
  </ThumbBase>
</template>

<translations>
  level: 'Level'
  level_no: 'Nivå'
</translations>

<script>
import ThumbBase from '../../../components/ThumbBase.vue';

const images = require.context('./images/', true, /\.png$/);

export default {
    components: { ThumbBase },
    props: {
        gameId: {
            type: String,
            required: true
        }
    },
    computed: {
        relatedLifeskillNumbers() {
            return ['02', ...this.game.relatedLifeskills];
        },
        game() {
            return this.$store.state.moduleMemogenius.games[this.gameId];
        },
        image() {
            try {
                return images(`./${this.gameId}.png`);
            } catch (err) {
                return null;
            }
        },
        highestCompletedLevelInfo() {
            return this.$store.getters['moduleMemogenius/getHighestCompletedLevelInfo'](this.gameId);
        }
    },
    methods: {
        gotoGame() {
            this.$store.commit('moduleApp/setGameExitTo', this.$route.path);
            this.$router.replace({
                name: 'MemogeniusGame-Levels',
                params: {
                    gameId: this.gameId
                }
            });
        },
        gotoLifeskill(lifeskillId) {
            this.$router.push({
                name: 'DashboardLifeskillView',
                params: { lifeskillId }
            });
        }
    }
};
</script>

<style lang="scss" scoped>

.GameItem {
    /deep/ {
        min-height: 0;
    }
}
.highestCompletedLevelInfo {
    position: absolute;
    left: 1em;
    top: 1em;
    color: rgba(white, 0.5);
    display: flex;
    align-items: center;
}
.stars {
    display: flex;
    margin-left: 0.5em;
}
.star {
    $size: 0.8em;
    width: $size;
    height: $size;
    margin: 0 0.1em;
    &.is-full {
        fill: yellow;
    }
    &.is-empty {
        display: none;
    }
}
</style>
