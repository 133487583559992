<template>
  <div class="AuraSection">
    <Aura />
  </div>
</template>

<script>
import Aura from '@/modules/repetition/Aura';

export default {
  components: { Aura, },
  data: function() {
    return {
    }
  },
}
</script>

<style lang="scss" scoped>
.AuraSection {
  @media (max-width: 620px) {
    display: flex;
    width: 100vw;
  }
}
</style>

