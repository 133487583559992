<template>
  <div
    class="VideoItem --global-clickable"
    :data-video-id="videoId"
    @click="!isDragging && gotoVideo()">
    <div class="card">
      <div class="layer">
        <div class="play-button">
          <svgicon
            icon="play-button"
            class="icon" />
        </div>
      </div>
      <div
        v-lazy:background-image="thumbnail"
        class="thumbnail" />
    </div>

    <div class="title">
      {{ title }}
    </div>

    <LifeskillNumberTag
      class="tag"
      :lifeskill-id="lifeskillId"
      :is-category-section="true" />

  </div>
</template>
 
<translations>
     brainDates: '{count} brain dates'
     brainDates_no: '{count} hjernedates'
     brainDatesCompletedOfTotal: '{completed} of {total} brain bates'
     brainDatesCompletedOfTotal_no: '{completed} av {total} hjernedates'
</translations>
 
<script>
import LifeskillNumberTag from '@/modules/dashboard/components/LifeskillNumberTag';
 
 export default {
    components: { LifeskillNumberTag },
     props: {
         videoId: {
             type: String,
             required: true
         },
         lifeskillId: {
             type: String,
             default: null
         },
         videoTitle: {
             type: String,
             default: null
         },
         videoDuration: {
             type: Number,
             default: null
         },
         videoThumbnail: {
             type: String,
             default: null
         },
         isDragging: {
             type: Boolean,
             default: false
         }
     },
 
     computed: {
         video() {
             return this.$store.getters['moduleApp/getVideoById'](this.videoId);
         },
 
         thumbnail() {
             return this.videoThumbnail || this.video.thumbnail;
         },
 
         title() {
             if (this.videoTitle) {
                 return this.videoTitle;
             }
 
             return this.video.title.length > 80 ? this.video.title.substr(0, 76) + ' ...' : this.video.title;
         },
 
     },
 
     methods: {
         gotoVideo() {
             this.$router.push({
                 name: 'DashboardVideoView',
                 params: {
                     videoId: this.videoId,
                     lifeskillId: this.lifeskillId || this.video.lifeskillIds[0]
                 }
             });
 
             this.$emit('clicked-video', this.videoId);
         }
     }
 };
</script>
 
<style lang="scss" scoped>
 $transitionDuration: 0.3s;
 
 .VideoItem {
    position: relative;
     display: flex;
     flex-direction: column;
     width: 12em;
     height: 12.2em;
     border-radius: 0.5em;
     background-color: #263576;
     margin-left: 0.7em;

     @media (max-width: 530px) {
        margin-left: 0;
     }
 
     .braindates-count {
         color: #5983f7;
         font-size: 16px;
         @media (max-width: 530px) {
             font-size: 14px;
         }
     }
 
     &:hover {
         .layer {
             visibility: visible;
         }
     }
     .tag {
        position: absolute;
        right: 0;
        top: 0;
    }
 }
 
 .layer {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     background: rgba(34, 29, 29, 0.3);
     visibility: hidden;
     display: flex;
     align-items: center;
     justify-content: center;
 
     .play-button {
         width: 6em;
         height: 4em;
         border-radius: 0.5em;
         background: #5983f7;
         opacity: 0.7;
         display: flex;
         justify-content: center;
         align-items: center;
         .icon {
             fill: white;
             width: 2em;
         }
     }
 }
 
 .card {
     position: relative;
 
     transform: translate3d(0, 0, 0); // Avoid Safari visual bug, where overflow: hidden is ignored while scaling .image
     box-shadow: $dashboard-itemShadow;
 }
 .thumbnail {
     background-position: center;
     transition: transform $transitionDuration;
     background-size: 100% 135%;
     height: 7em;

     border-radius: 0.25em;
 }
 
 .label {
     color: rgba(white, 0.5);
     font-size: 80%;
 }
 .title {
     margin: 0.6em;
     line-height: 1.3;
     font-size: 15px;
     color: #EEEEEE;

     overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 3;
     line-clamp: 3; 
     -webkit-box-orient: vertical;
     
     @media (max-width: 530px) {
         font-size: 3.7vw;
         margin: 2.2vw;
         line-height: 1.3;
     }
 }
</style>
  