<template>
  <div class="BraindateItemBase">
    <img class="stepIcon" :src="icon" alt="" draggable="false" />
    <div class="titleContainer">
      <div class="title"> {{ title }} </div>
      <div class="subtitle"> {{ subtitle }}</div>
    </div>
    <img v-if="extraIcon" class="extraIcon" :src="extraIcon" alt="" draggable="false" />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    extraIcon: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
.BraindateItemBase {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 1em;
  margin: 0.6em 0;
  height: 6em;
  background: #26216f62;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    margin: 2vw 2vw;
    height: 18vw;
    border-radius: 2vw;
  }

  &.missionNotSelected {
    justify-content: center;
    cursor: pointer;
  }

  .stepIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1.5em;
    border-radius: 10em;

    width: 3em;
    height: 3em;

    @media only screen and (max-width: 600px) {
      margin-left: 2.3vw;
      width: 8vw;
      height: 8vw;
    }
  }
  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 1em;
    width: 16em;

    @media only screen and (max-width: 600px) {
      padding: 3vw;
      padding-right: 5vw;
      width: 50vw;
    }

    .title {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: 0.5em;
      margin-right: 1.26em;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      @media only screen and (max-width: 600px) {
        font-size: 3.2vw;
        margin-bottom: 2vw;
      }
    }
    .subtitle {
      font-size: 1em;
      font-weight: 500;

      color: #9e9cd1;
      @media only screen and (max-width: 600px) {
        font-size: 3vw;
      }
    }
  }
  .extraIcon {
    position: absolute;
    top: 1em;
    right: 1em;
    height: 1em;
    @media only screen and (max-width: 600px) {
      top: 2vw;
      right: 2vw;
      font-size: 2.5vw;
    }
  }
}
</style>
