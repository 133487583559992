<template>
  <div class="TeamMembersProgressDemo">
    <h2>Team Members Progress Demo</h2>
    <!-- <div v-if=""></div> -->
    <div v-for="member in members" :key="member.name">
      <div>{{ member.name }}</div>
      <div>{{ member.progress }}</div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    members() {
      return Object.values(this.$store.state.moduleTeam.members)
    },
  },
  watch: {
    selectedTeamId: {
      immediate: true,
      handler(teamId) {
        this.$store.dispatch('moduleTeam/load', teamId)
      },
    },
  },
  mounted() {
    // this.$store.dispatch('moduleTeam/load')
  },
}
</script>

<style scoped lang="scss">
.TeamMembersProgressDemo {
  // background-color: #fff;
  // height: 100px;
  width: 100%;
  border: 1px solid white;
}
</style>
