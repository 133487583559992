<template>
  <ThumbBase
    class="IndependentCourseThumb"
    :image="image"
    :title="title"
    :icon="icon"
    @click="gotoCourse()" />
</template>

<script>
import ThumbBase from './ThumbBase';

export default {
    components: { ThumbBase },
    props: {
        courseId: {
            type: String,
            required: true
        }
    },
    computed: {
        course() {
            return this.$store.state.moduleCourse.courses[this.courseId];
        },
        image() {
            return this.course.thumb;
        },
        title() {
            return this.course.title;
        },
        icon() {
            return '';
        }
    },
    methods: {
        gotoCourse() {
            this.$router.push({
                name: 'CourseHomeView',
                params: {
                    courseId: this.courseId
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$transitionDuration: 0.3s;

.IndependentCourseThumb {
    width: (286em / 15);
    height: 13em;
}
</style>
