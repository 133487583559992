<template>
  <div class="BeltSection">
    <div class="avatar">
      <Belt
        class="beltIcon"
        v-bind="beltProps" />
    </div>

    <div class="info">
      <div class="headerInfoWrapper">
        <div
          v-if="belt.current"
          class="grade">
          <h2>{{ $t(`belts.beltGrades.${belt.current.id}`) }} {{ $t('belt') }}</h2>
        </div>
        <div
          v-if="!belt.current"
          class="grade">
          <h2>{{ $t('belt') }}</h2>
        </div>

        <NextBeltProgressText class="NextBeltProgressText" :belt="belt" />
      </div>

      <div
        v-if="belt.next"
        class="lifekeys">
        <Lifekey
          v-for="i of belt.next.requiredPoints"
          :key="i"
          :completed="i <= belt.points"
          :class="{ completed: i <= belt.points }" />
      </div>
    </div>
  </div>
</template>

<translations>
title: Your belt
title_no: Ditt belte

belt: 'belt'
belt_no: 'belte'
noBelt: ' '
noBelt_no: ' '
# progressText: '{count} life key left for the next belt | {count} life keys left for the next belt'
# progressText_no: '{count} livsnøkkel igjen til neste belte | {count} livsnøkler igjen til neste belte'

# firstBeltProgressText: '{count} life key for your first belt | {count} life keys for your first belt'
# firstBeltProgressText_no: '{count} livsnøkkel til ditt første belte | {count} livsnøkler til ditt første belte'

viewLeaderboard: 'View leaderboard'
yourRank: 'Your Rank'
</translations>

<script>
import Belt from './Belt';
import Lifekey from '../Lifekey';
import NextBeltProgressText from '../NextBeltProgressText';

export default {
  components: { Belt, Lifekey, NextBeltProgressText },
  computed: {
    belt() {
      return this.$store.getters['moduleApp/belt'];
    },
    beltProps() {
      return {
        color: this.belt.current && this.belt.current.color,
        avatar: this.$store.getters['moduleAuth/avatar']
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.BeltSection {
  display: flex;
  padding-left: 1em;
  padding-right: 0.5em;
  justify-content: space-between;
  height: 14em;
  // width: 35em;
  @media (max-width: 620px) {
    display: flex;
    height: none;
    width: 100vw;
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

.NextBeltProgressText {
  @media (max-width: 620px) {
    width: 55vw;
    line-height: 5vw;
  }
}

.avatar{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
  margin-right: 1em;
  @media (max-width: 620px) {
    padding: 0;
    margin-right: 0;
  }
}
.info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  color: rgba(255, 255, 255, 0.75);
  @media (max-width: 620px) {
    justify-content: center;
  }
  .headerInfoWrapper {
    
    h2 {
      color: white;
      margin-bottom: 0.5em;
    }
  }

}

.beltIcon {
  $size: 140px;
  width: $size;
  height: $size;
  flex-shrink: 0;
}
.lifekeys {
  display: flex;
  padding-top: 2em;
  @media (max-width: 620px) {
    padding-top: 5vw;
  }
}

.Lifekey {
  position: relative;
  $size: 52px;
  width: $size;
  height: $size;
  flex-shrink: 0;
  margin-right: 1em;

  @media (max-width: 620px) {
    width: 8vw;
    height: 8vw;
    margin-right: 2vw;
  }

  &.completed {
    box-shadow: 0px 0px 16px 4px #40D4F41F;
    &::after {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid #6feef6;
      border-radius: 50%;
      box-shadow: inset 0 0 3px 0px #6feef6;
    }
  }
}

.textContainer {
  margin-left: 2em;
}
.beltName {
  color: rgba(white, 0.9);
  font-weight: 600;
}
.beltName--noBelt {
  text-transform: none;
  font-style: italic;
  font-weight: normal;
  color: rgba(white, 0.5);
}
.grade {
  text-transform: uppercase;
}
.nextBeltProgress {
  color: rgba(white, 0.5);
  margin-top: 0.3em;
}
.nextBeltProgressContainer {
  display: flex;
  align-items: center;
}
</style>
