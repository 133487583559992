<template>
  <RocketContainer
    class="HabitMissionBraindatesRow"
    :title="$t('habits')"
    :badge-image="getMissionBadgeImage()"
    :progress="getMissionsProgress"
    :team-progress="getMissionsProgress"
    :bottom-text="missionName"
    :show-edit-icon="habitMissionBraindates.length > 0"
    @clickedRocketBottom="expandMissions"
  >
    <template v-if="habitMissionBraindates.length" #items>
      <HabitMissionBraindateItem v-for="item of habitMissionBraindates" :key="item.title" :item="item" />

      <DummyItem v-if="habitMissionBraindates.length < 2" />
      <DummyItem v-if="habitMissionBraindates.length < 3" />
      <DummyItem v-if="habitMissionBraindates.length < 4" />
    </template>

    <template v-else #items>
      <DummyItem @click.native="expandMissions">
        <div class="chooseTitle">
          {{ $t('choose1') }} <br />
          {{ $t('choose2') }}
        </div>
      </DummyItem>
      <DummyItem @click.native="expandMissions" />
      <DummyItem @click.native="expandMissions" />
    </template>
  </RocketContainer>
</template>

<translations>
  missionNotSelectedTitle: 'Choose a habit mission:'
  missionNotSelectedTitle_no: 'Velg et oppdrag for vaner:'

  multipleMissions: 'Multiple missions'
  multipleMissions_no: 'Flere oppdrag'

  habits: 'HABITS'
  habits_no: 'VANER'

  choose: 'Choose missions:'
  choose_no: 'Velg oppdrag:'
 
  choose1: Get your
  choose1_no: Få dine 
  choose2: daily brain dates
  choose2_no: daglige hjernedates

  ChooseSolo: 'Choose solo mission'
  ChooseSolo_no: 'Velg solo-oppdrag'
  ChooseTeam: 'Choose team mission'
  ChooseTeam_no: 'Velg team-oppdrag'
</translations>

<script>
import { mapActions } from 'vuex'
import HabitsMissionSectionPopup from '../MissionsSection/HabitsMissionSectionPopup'
import RocketContainer from './RocketContainer.vue'
import HabitMissionBraindateItem from './HabitMissionBraindateItem.vue'
import DummyItem from './DummyItem.vue'

const habitBadgeImages = require.context('../MissionsSection/assets/images', true, /\.(png)$/)

export default {
  components: { RocketContainer, HabitMissionBraindateItem, DummyItem },

  computed: {
    selectedTeamId() {
      return this.$store.state.moduleApp.memolife.selectedTeamId
    },
    sourceLanguage() {
      return this.$store.getters['moduleApp/locale']
    },
    selectedHabitMissions() {
      return this.$store.getters['moduleApp/selectedHabitMissions']()
    },
    habitMissionBraindates() {
      return this.$store.getters['moduleApp/getHabitBraindates']
    },
    missionName() {
      if (this.selectedHabitMissions.length === 0) {
        if (this.selectedTeamId === '0') {
          return this.$t('ChooseSolo')
        } else {
          return this.$t('ChooseTeam')
        }
      } else if (this.selectedHabitMissions.length > 1) {
        return this.$t('multipleMissions')
      } else {
        if (this.sourceLanguage === 'no') {
          return this.selectedHabitMissions[0].missionName_no
        } else {
          return this.selectedHabitMissions[0].missionName
        }
      }
    },
    getMissionsProgress() {
      let progress = 0
      this.selectedHabitMissions.forEach(mission => {
        const dayCount = mission.days.length
        if (dayCount <= 22) {
          progress = progress + dayCount / 22
        } else if (dayCount > 22 && dayCount <= 44) {
          progress = progress + (dayCount - 22) / 22
        } else if (dayCount > 44) {
          progress = progress + (dayCount - 44) / 22
        }
      })
      if (this.habitMissionBraindates.length) {
        return progress / this.selectedHabitMissions.length
      } else {
        return 0
      }
    },
  },
  mounted() {
    this.fetchMentalHygieneStats()
  },
  methods: {
    ...mapActions({ fetchMentalHygieneStats: 'moduleMentalhygiene/load' }),

    expandMissions() {
      this.$modal.open({
        component: HabitsMissionSectionPopup,
        target: 'app-modal',
      })
    },

    getMissionBadgeImage() {
      if (this.selectedHabitMissions.length > 1) {
        return habitBadgeImages('./multiple-selection-circular.png')
      } else if (this.selectedHabitMissions.length === 1) {
        return habitBadgeImages(`./${this.selectedHabitMissions[0].missionId}-circular.png`)
      } else {
        return habitBadgeImages('./not-selected-circular.png')
      }
    },
  },
}
</script>
