<template>
  <SectionBase
    v-if="independentCourseIds.length"
    class="IndependentCoursesSection"
    :title="$t('title')">
    <template #items>
      <IndependentCourseThumb
        v-for="courseId of independentCourseIds"
        :key="courseId"
        :course-id="courseId" />
    </template>
  </SectionBase>
</template>

<translations>
  title: 'Company courses'
  title_no: 'Bedriftskurs'
</translations>

<script>
import SectionBase from './SectionBase';
import IndependentCourseThumb from './IndependentCourseThumb';

export default {
    components: { SectionBase, IndependentCourseThumb },
    computed: {
        independentCourseIds() {
            return this.$store.getters['moduleCourse/independentCourseIds'];
        }
    }
};
</script>
