<template>
  <div
    class="MemoransierGameItem"
    :class="`dataId-${gameOptions.dataId}`">
    <div class="content">
      <div
        class="title"
        v-html="$t(`${gameOptions.dataId}.title`)" />
      <div class="text">
        {{ $t(`${gameOptions.dataId}.text`) }}
      </div>
      <StandardButton
        class="button"
        :label="$t('startGame')"
        icon="arrow-right"
        @click="gotoMemoransierGame(id)" />
    </div>
    <div class="background" />
  </div>
</template>

<translations>
  startGame: 'Start memory game'
  startGame_no: 'Start huskespill'

  aim2north:
    title: 'Stay Ahead of Change'
    text: '6 brain hacks for becoming future-proof'
  technipfmc:
    title: 'Future Ready with TechnipFMC'
    text: '6 brain hacks for becoming future-proof'
  techdata:
    title: 'Techdata Microsoft Cloud<br>Event 2019'
    text: 'Husker du de viktigste ''takeawaysene'' fra konferansen?'
  xledger:
    title: 'Ligg et hode foran med Xledger'
    text: 'Spill hjernen topptrent i dag, mens du lynraskt lærer ferdighetene for i morgen'
  sats:
    title: 'Ligg et hode foran med Sats'
    text: 'Spill hjernen topptrent i dag, mens du lynraskt lærer ferdighetene for i morgen'
  tekna:
    title: 'Fremtidsklar med Tekna'
    text: ' '

  storebrand:
    title: 'Storebrand'
    text: ' '
</translations>

<script>
import StandardButton from '@/components/StandardButton';

export default {
    components: { StandardButton },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        game() {
            return this.$store.state.moduleMemoransier.games.find(g => g.id === this.id);
        },
        gameOptions() {
            return (this.game && this.game.options) || {};
        }
    },
    methods: {
        gotoMemoransierGame(gameId) {
            this.$router.push({
                name: 'MemoransierLobby',
                params: { gameId }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
$breakpoint: 760px;
.MemoransierGameItem {
    background-color: #00b9e7;
    border-radius: $dashboard-itemBorderRadius;
    overflow: hidden;
    color: black;

    display: flex;
    justify-content: space-between;
    align-items: stretch;

    &.dataId-techdata {
        .background {
            background-image: url('../../../assets/images/signup-partner/techdata-memoransier.png');
        }
    }
    &.dataId-aim2north {
        background-color: #457ea1;
        .background {
            background-image: url('../../../assets/images/signup-partner/aim2north-memoransier.jpg');
        }
    }
    &.dataId-technipfmc {
        background-color: #457ea1;
        .background {
            background-image: url('../../../assets/images/signup-partner/technipfmc-memoransier.jpg');
        }
    }
    &.dataId-xledger {
        background-color: #53a2d6;
        .background {
            background-image: url('../../../assets/images/signup-partner/xledger-memoransier.png');
        }
    }
    &.dataId-sats {
        background-color: #466988;
        .background {
            background-image: url('../../../assets/images/signup-partner/sats-memoransier.jpg');
            background-position: center;
        }
    }
    &.dataId-tekna {
        min-height: 13em;
        background-color: #53a2d6;
        .background {
            background-image: url('../../../assets/images/signup-partner/tekna-memoransier.jpg');
        }
    }
    &.dataId-storebrand {
        background-color: #457ea1;
        .background {
            background-image: url('../../../assets/images/signup-partner/aim2north-memoransier.jpg');
        }
    }

}
.content {
    max-width: 20em;
    min-width: 13em;
    padding: 1.5em;
    padding-right: 0.5em;
}
.title {
    font-size: 140%;
    font-weight: 600;
}
.text {
    margin-top: 1em;
}
.button {
    margin-top: 1em;
}

.background {
    background-size: auto 102%;
    background-repeat: no-repeat;
    background-position: center right;
    flex: 1;
    min-width: 13em;
    margin-right: -2px;
}

@include media('<#{$breakpoint}') {
    .background {
        max-width: 20em;
        background-position: center left;
    }
}
</style>
